import React from 'react';
import SystemToast from '@client/components/SystemToast';
import { raiseToast } from '@client/components/Toaster';
import { retrieveDriverJobs } from '@client/_blessed/services/reactiveDualTrans/retrieveDriverJobs';
import { retrieveDrivers } from '@client/_blessed/services/reactiveDualTrans/retrieveDrivers';
import {
  fetchDualIngateGeneratorBundles,
  fetchDualInMatches,
  fetchGeneratorLegs
} from '@client/_blessed/services/reactiveDualTrans/retrieveDualInMatches';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GeneratorBundle } from '@client/_blessed/store/entities';
import { sliceId } from './sliceId';

export const retrieveDriverJobsAction = createAsyncThunk<{ data: any }, any>(
  `${sliceId}/retrieveDriverJobs`,
  async (params: { driverID: string }, thunkAPI) => {
    try {
      const resp: any = await retrieveDriverJobs(params, { abortKey: `${sliceId}/retrieveDriverJobs` });
      return { data: resp.data };
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable retrieve driver jobs" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const loadTableDataAction = createAsyncThunk(
  `${sliceId}/loadTableData`,
  async (params: { driverID: string }, thunkAPI) => {
    try {
      await thunkAPI.dispatch(retrieveDriverJobsAction(params));
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to load table data" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const retrieveDriversAction = createAsyncThunk<{ data: any }>(
  `${sliceId}/retrieveDrivers`,
  async (_, thunkAPI) => {
    try {
      const resp: any = await retrieveDrivers(_, { abortKey: `${sliceId}/retrieveDriverJobs` });
      return { data: resp.data };
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable retrieve drivers" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const retrieveDualInMatchesAction = createAsyncThunk<{ data: any }, any>(
  `${sliceId}/retrieveDualInMatches`,
  async (params: { legID: string }, thunkAPI) => {
    try {
      const resp: any = await fetchDualInMatches(params, { abortKey: `${sliceId}/retrieveDualInMatches` });
      return { data: resp.data };
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable load ingate matches" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const retrieveDualIngateGeneratorLegMatches = createAsyncThunk<{ data: any }, any>(
  `${sliceId}/retrieveDualIngateGeneratorLegMatches`,
  async (params: { legNumber: number }, thunkAPI) => {
    try {
      const bundleResp: any = await fetchDualIngateGeneratorBundles(params, {
        abortKey: `${sliceId}/retrieveDualIngateGeneratorLegMatches`
      });
      const legNumbersToFetch: number[] = [];
      bundleResp.data.forEach((item: GeneratorBundle) => {
        const [legNumber] = item.id.split('::');
        legNumbersToFetch.push(Number(legNumber));
      });
      const legResp: any = await fetchGeneratorLegs(legNumbersToFetch);
      return { data: legResp.data };
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable load ingate nmatches" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);
