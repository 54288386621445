import React, { ReactElement } from 'react';
import Button from '@client/components/Button/component';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { setSubscribeComponent } from '../../../../SubscriptionNotification/stores';
import { selectShowSubscribeComponent } from '../../../../SubscriptionNotification/stores/selectors';

import './styles.css';

function SubscribeToContainerButton(): ReactElement {
  const dispatch = useAppDispatch();
  const baseClassName = 'subscribe-to-container-button';
  const showSubscribeComponent = useSelector(selectShowSubscribeComponent);

  const handleShowSubscribeComponent = () => {
    dispatch(setSubscribeComponent(true));
  };

  return (
    <div className={baseClassName}>
      {!showSubscribeComponent && (
        <Button
          onClick={handleShowSubscribeComponent}
          label="Subscribe to Containers"
          theme="2"
          type="button"
          id="showSubscribeComponent"
        />
      )}
    </div>
  );
}

export default SubscribeToContainerButton;
