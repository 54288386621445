// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.0025em;
  text-align: left;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/BackButton/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".back-button {\n  margin-top: 5px;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 18px;\n  letter-spacing: 0.0025em;\n  text-align: left;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
