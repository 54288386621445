// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supported-terminal-booking {
  margin: 15px 0;
  width: 100%;
}

  .supported-terminal-booking__title {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 10px 0;
  }

  .supported-terminal-booking__table {
    width: 100%;
    table-layout: fixed;
  }

  .supported-terminal-booking__table th {
      padding: 10px;
      text-align: center;
      border: 1px solid black;
      width: 100px;
      font-weight: 500;
    }

  .supported-terminal-booking__table td {
      padding: 10px 5px;
      text-align: left;
      border: 1px solid black;
    }

  .supported-terminal-booking__table td svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

  .supported-terminal-booking__table td .status {
        margin-bottom: 8px;
        text-transform: uppercase;
      }

  .supported-terminal-booking__table td .status > div {
          margin: 10px 0;
        }

  .supported-terminal-booking__table--zero {
      border: none !important;
    }

  .supported-terminal-booking--on {
    color: var(--color-green-3);
  }

  .supported-terminal-booking--off {
    color: var(--color-red-3);
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/SupportedTerminalBooking/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;AAqDb;;EAnDE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,mBAAmB;EAkCrB;;EAhCE;MACE,aAAa;MACb,kBAAkB;MAClB,uBAAuB;MACvB,YAAY;MACZ,gBAAgB;IAClB;;EAEA;MACE,iBAAiB;MACjB,gBAAgB;MAChB,uBAAuB;IAgBzB;;EAdE;QACE,WAAW;QACX,YAAY;QACZ,iBAAiB;MACnB;;EAEA;QACE,kBAAkB;QAClB,yBAAyB;MAK3B;;EAHE;UACE,cAAc;QAChB;;EAIJ;MACE,uBAAuB;IACzB;;EAGF;IACE,2BAA2B;EAC7B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".supported-terminal-booking {\n  margin: 15px 0;\n  width: 100%;\n\n  &__title {\n    font-size: 1.2rem;\n    font-weight: 600;\n    margin: 10px 0;\n  }\n\n  &__table {\n    width: 100%;\n    table-layout: fixed;\n\n    th {\n      padding: 10px;\n      text-align: center;\n      border: 1px solid black;\n      width: 100px;\n      font-weight: 500;\n    }\n\n    td {\n      padding: 10px 5px;\n      text-align: left;\n      border: 1px solid black;\n\n      svg {\n        width: 20px;\n        height: 20px;\n        margin-right: 5px;\n      }\n\n      .status {\n        margin-bottom: 8px;\n        text-transform: uppercase;\n\n        & > div {\n          margin: 10px 0;\n        }\n      }\n    }\n\n    &--zero {\n      border: none !important;\n    }\n  }\n\n  &--on {\n    color: var(--color-green-3);\n  }\n\n  &--off {\n    color: var(--color-red-3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
