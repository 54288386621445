// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscribe-to-container-button {
  display: flex;
  justify-content: end;
  align-items: end;
}
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/SubscribeToContainerButton/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":[".subscribe-to-container-button {\n  display: flex;\n  justify-content: end;\n  align-items: end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
