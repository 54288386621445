import { useEffect } from 'react';
import { logAnalyticsPageView } from '@client/utils/analytics';
import { useSelector } from 'react-redux';
import {
  fetchOneContainerOnHireStatus,
  selectContainerOnHireStatusesByContainerNumber,
  selectDepartmentsById
} from '@client/_blessed/store/entities';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import store from './store';
import {
  loadTableDataAction,
  retrieveDriversAction,
  retrieveDualIngateGeneratorLegMatches,
  retrieveDualInMatchesAction
} from './store/asyncActions';
import {
  selectDriverDropdownOptions,
  selectDriversById,
  selectGeneratorMatchLocations,
  selectMatchLocations,
  selectMatchSort,
  selectSortedGeneratorMatches,
  selectSortedMatches,
  selectTableSort,
  tableDataSelector,
  selectMatchesLoading
} from './store/selectors';

export function useLogDualTransPageView() {
  useEffect(() => {
    logAnalyticsPageView('admin/reactive-dual-trans');
  }, []);
}

export function useLogFindDualTransCandidatesInDispatchPage() {
  useEffect(() => {
    logAnalyticsPageView('dispatch/find-dual-trans');
  }, []);
}

export function useReactiveDualTrans() {
  const dispatch = useAppDispatch();
  const featFlags = useFlags();
  const tableData = useSelector(tableDataSelector);
  const driverOptions = useSelector(selectDriverDropdownOptions);
  const driversById = useSelector(selectDriversById);
  const departmentsById = useSelector(selectDepartmentsById);
  const tableSort = useSelector(selectTableSort);
  const matches = useSelector(selectSortedMatches);
  const matchSort = useSelector(selectMatchSort);
  const matchLocationOptions = useSelector(selectMatchLocations);
  const generatorMatches = useSelector(selectSortedGeneratorMatches);
  const generatorLocationOptions = useSelector(selectGeneratorMatchLocations);
  const onHireStatusesByContainer = useSelector(selectContainerOnHireStatusesByContainerNumber);
  const matchesLoading = useSelector(selectMatchesLoading);
  // use stored (DB) generator_bundles if true
  const useNewBundles = !!featFlags.da6566?.reactiveDualTrans;
  const locationOptions = useNewBundles ? generatorLocationOptions : matchLocationOptions;

  const loadTableData = (params: { driverID: string }) => {
    dispatch(loadTableDataAction(params));
  };

  const loadDrivers = () => {
    dispatch(retrieveDriversAction());
  };

  const loadMatches = async (params: { legID: string; legNumber: number }) => {
    if (useNewBundles) {
      dispatch(retrieveDualIngateGeneratorLegMatches(params));
    } else {
      dispatch(retrieveDualInMatchesAction(params));
    }
  };

  const handleTableSort = (value: any) => {
    dispatch(store.actions.sortTable(value));
  };

  const handleMatchSort = (value: any) => {
    dispatch(store.actions.sortMatches(value));
  };

  const resetMatches = () => {
    if (useNewBundles) {
      dispatch(store.actions.resetGeneratorMatches());
    } else {
      dispatch(store.actions.resetMatches());
    }
  };

  useEffect(() => {
    loadDrivers();

    return () => {
      dispatch(store.actions.reset());
    };
  }, []);

  useEffect(() => {
    let ingateMatches = matches;
    if (useNewBundles) {
      ingateMatches = generatorMatches;
    }
    ingateMatches.forEach((match) => {
      dispatch(
        fetchOneContainerOnHireStatus({ containerNumber: match.containerNumber, orderNumber: match.orderNumber })
      );
    });
  }, [generatorMatches, matches]);

  return {
    tableData,
    loadTableData,
    handleTableSort,
    handleMatchSort,
    loadMatches,
    resetMatches,
    driverOptions,
    driversById,
    departmentsById,
    tableSort,
    matchSort,
    matches,
    generatorMatches,
    matchesLoading,
    locationOptions,
    useNewBundles,
    onHireStatusesByContainer
  };
}
