// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appointment-booking {
  margin: 15px;
  width: 100%;
  max-width: 1500px;
}

  .appointment-booking__content-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    max-height: 85vh;
    overflow-y: scroll;
    height: 100%;
  }

  .appointment-booking h2 {
    margin: 25px 0 10px; 
    font-size: 1.6rem;
    font-weight: 600;
  }

  .appointment-booking__loader-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .appointment-booking__loading {
    width: 5%;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;AA0BnB;;EAxBE;IACE,aAAa;IACb,0BAA0B;IAC1B,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE,SAAS;EACX","sourcesContent":[".appointment-booking {\n  margin: 15px;\n  width: 100%;\n  max-width: 1500px;\n\n  &__content-wrapper {\n    display: grid;\n    grid-template-columns: 1fr;\n    width: 100%;\n    max-height: 85vh;\n    overflow-y: scroll;\n    height: 100%;\n  }\n\n  h2 {\n    margin: 25px 0 10px; \n    font-size: 1.6rem;\n    font-weight: 600;\n  }\n\n  &__loader-wrapper {\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n  }\n\n  &__loading {\n    width: 5%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
