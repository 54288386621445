import React, { MouseEvent, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import TextInput from '@client/components/TextInput/component';
import LetterBadge from '@client/components/LetterBadge';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { updateSort } from '../../../../SubscriptionNotification/stores';
import SubscriptionListItems from '../SubscriptionListItems';
import SubscribeToContainerButton from '../SubscribeToContainerButton/component';
import { selectSorting } from '../../../../SubscriptionNotification/stores/selectors';
import type { SubscriptionNotificationUiData } from '../../container';

import './styles.css';

interface Props {
  subscriptionNotifications?: SubscriptionNotificationUiData[];
  handleQuery: (query: string) => void;
  searchQuery: string;
}

export const sortFields = {
  Container: 'containerNumber',
  Terminal: 'terminal',
  LFD: 'lfd',
  'Per-Diem Due Date': 'perDiemDueDate',
  Subscriber: 'subscriber',
  Subscriptions: 'subscriptions',
  'Booking Delay': 'bookingDelay',
  'Last Checked': 'lastChecked'
};

function SubscriptionTable(props: Props): ReactElement {
  const baseClassName = 'subscription-table';
  const dispatch = useAppDispatch();

  const sort = useSelector(selectSorting);

  const handleSort = (e: MouseEvent<HTMLButtonElement>, value: string) => {
    e.preventDefault();
    dispatch(updateSort(value));
  };
  const headerLabels = Object.keys(sortFields);

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__header-wrapper`}>
        <h2>Active Notification Subscriptions</h2>
        <SubscribeToContainerButton />
      </div>
      <TextInput
        className={`${baseClassName}__textarea`}
        onChange={props.handleQuery}
        value={props.searchQuery}
        name="Search subscriptions"
        placeholder="Enter container, terminal, or subscriber here separated by commas"
      />
      <table>
        <thead>
          <tr>
            {headerLabels.map((label: string) => (
              <th key={label}>
                <button
                  className={`${baseClassName}__sort-btn`}
                  type="button"
                  onClick={(e) => handleSort(e, sortFields[label as keyof typeof sortFields])}
                >
                  {label}
                  {label === 'Booking Delay' && (
                    <span className={`${baseClassName}__tool-tip-wrapper`}>
                      <LetterBadge
                        className={`${baseClassName}__subscriptions-tool-tip`}
                        text="I"
                        toolTipId="delay-tool-tip"
                        toolTipMessage="The minimum time between dray bot finding an appointment, and the appointment it chooses"
                        aria-label="Booking delay"
                      />
                    </span>
                  )}{' '}
                  <br />
                  {sort.field === sortFields[label as keyof typeof sortFields] ? `(${sort.direction})` : ''}
                </button>
              </th>
            ))}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {props.subscriptionNotifications?.map((subscription) => (
            <SubscriptionListItems subscription={subscription} key={subscription.uuid} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(SubscriptionTable);
