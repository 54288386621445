import React, { useState } from 'react';
import EditableField from '@client/components/EditableField';
import moment from 'moment-timezone';
import './style.css';
import { dropOrLiveToUIMappings, importExportUIMappings, legStatusesToUIMappings } from '@client/constants';
import CopyButton from '@client/components/CopyButton';
import PhoneButton from '@client/components/PhoneButton';
import { DualIngateSmartMatch } from '@client/_blessed/models';
import { ContainerOnStatusEntity, GeneratorLeg } from '@client/_blessed/store/entities';
import { TIME_ZONES } from '@drayalliance/types';
import LoadingSpinner from '../../../../../../../../components/LoadingSpinner';

interface Props {
  selectedLeg: any;
  departmentsById: any;
  driver: any;
  matches: DualIngateSmartMatch[];
  generatorMatches: GeneratorLeg[];
  matchesLoading?: boolean;
  matchSort: any;
  locationOptions: { value: string }[];
  useNewBundles: boolean;
  onHireStatusesByContainer: Record<string, ContainerOnStatusEntity>;
  // eslint-disable-next-line no-unused-vars
  onMatchSort: (value: any) => void;
}

interface RowMatch {
  legNumber: string | number;
  status: string;
  driverName?: string;
  containerNumber: string;
  isOnHire: boolean;
  perDiemDueDate: string;
  firstStopName: string;
  lastStopName: string;
  returnAppointment: string;
  backhaulReadyDate: string;
}

interface RowProps {
  match: RowMatch;
}

function shortenStatus(status: string) {
  return status === 'Ready For Pickup - On Availability' ? 'Ready For Pickup' : status;
}

function formatDate(dateString: string, timezone?: TIME_ZONES) {
  const dateMoment = moment(dateString).tz(timezone || TIME_ZONES.AMERICA_LOS_ANGELES);
  if (!dateString || !dateMoment.isValid()) {
    return '';
  }

  return dateMoment.format('MM/DD/YYYY');
}

function formatAppointment(dateString: string, timezone?: TIME_ZONES) {
  const dateMoment = moment(dateString).tz(timezone || TIME_ZONES.AMERICA_LOS_ANGELES);
  if (!dateString || !dateMoment.isValid()) {
    return '';
  }

  return dateMoment.format('MM/DD HH:mm');
}

const mapSmartMatchesToRowMatches = (
  smartMatches: DualIngateSmartMatch[],
  onHireStatusesByContainer: Record<string, ContainerOnStatusEntity>,
  selectedLeg: any
): RowMatch[] =>
  smartMatches.map((match) => {
    const containerOnHireByTerminal = onHireStatusesByContainer?.[match.containerNumber]?.onHireStatusByTerminal || {};
    const isOnHire = !!containerOnHireByTerminal[selectedLeg?.firstStopDepartmentUuid];
    return {
      legNumber: match.legNumber,
      status: shortenStatus(legStatusesToUIMappings[match.legStatus]),
      driverName: match.driverName,
      containerNumber: match.containerNumber,
      isOnHire,
      perDiemDueDate: formatDate(match.perDiemDueDate),
      firstStopName: match.firstStopName,
      lastStopName: match.lastStopName,
      returnAppointment: formatAppointment(match.returnAppointment),
      backhaulReadyDate: formatAppointment(match.readyForPickupDate)
    };
  });

const mapGeneratorLegsToRowMatches = (
  generatorLegs: GeneratorLeg[],
  onHireStatusesByContainer: Record<string, ContainerOnStatusEntity>,
  selectedLeg: any
): RowMatch[] =>
  generatorLegs.map((genLeg) => {
    const containerOnHireByTerminal = onHireStatusesByContainer?.[genLeg.containerNumber]?.onHireStatusByTerminal || {};
    const isOnHire = !!containerOnHireByTerminal[selectedLeg?.firstStopDepartmentUuid];
    return {
      legNumber: genLeg.legNumber,
      status: shortenStatus(legStatusesToUIMappings[genLeg.status]),
      driverName: genLeg.driverName,
      containerNumber: genLeg.containerNumber,
      isOnHire,
      perDiemDueDate: formatDate(genLeg.perDiemDueDate),
      firstStopName: genLeg.firstStopName,
      lastStopName: genLeg.lastStopName,
      returnAppointment: formatAppointment(genLeg.lastStopStart),
      backhaulReadyDate: formatAppointment(genLeg.emptyReadyAt)
    };
  });

function Row(props: RowProps) {
  return (
    <tr key={props.match.legNumber}>
      <td>
        {props.match.legNumber}
        <CopyButton value={props.match.legNumber} />
      </td>
      <td>{props.match.status}</td>
      <td>{props.match.driverName}</td>
      <td>
        {props.match.containerNumber}
        <CopyButton value={props.match.containerNumber} />
      </td>
      <td className={!props.match.isOnHire ? 'dual-in-matches-sidebar__matches--red' : ''}>
        {props.match.isOnHire ? 'Yes' : 'No'}
      </td>
      <td>{props.match.perDiemDueDate}</td>
      <td>{props.match.firstStopName}</td>
      <td>{props.match.lastStopName}</td>
      <td>{props.match.returnAppointment}</td>
      <td>{props.match.backhaulReadyDate}</td>
    </tr>
  );
}

function DualInMatches(props: Props) {
  const sortDirection = props.matchSort.direction;
  const ssl = props.departmentsById[props.selectedLeg?.steamShippingLineAccountUuid];
  const phone = props.driver?.attributes?.mobile_phone;
  const toggleColumn = (column: string) => {
    props.onMatchSort({
      column,
      direction: (sortDirection || '').toUpperCase() === 'ASC' ? 'DESC' : 'ASC'
    });
  };

  let rowMatches: RowMatch[] = [
    ...mapSmartMatchesToRowMatches(props.matches, props.onHireStatusesByContainer, props.selectedLeg)
  ];

  if (props.useNewBundles) {
    rowMatches = [
      ...mapGeneratorLegsToRowMatches(props.generatorMatches, props.onHireStatusesByContainer, props.selectedLeg)
    ];
  }
  const [selectedLocation, selectLocation] = useState<string>('All locations');
  const className = 'dual-in-matches';
  const sortedColumn = props.matchSort.column;

  const filterMatches = (match: RowMatch) =>
    selectedLocation === 'All locations' || selectedLocation === match.firstStopName;

  return props.selectedLeg ? (
    <div className={className}>
      <div className={`${className}__header`}>
        <div className={`${className}__header-text`}>Backhaul Candidates</div>
        <EditableField
          type="dropdown"
          editable
          placeholder="All locations"
          value={selectedLocation}
          onChange={(val: string) => selectLocation(val)}
          inputProps={{
            valueField: 'value',
            labelField: 'label',
            options: [{ value: 'All locations', label: 'All locations' }, ...props.locationOptions]
          }}
        />
      </div>
      <table className={`${className}__selectedLeg`}>
        <tbody>
          <tr>
            <td>
              <h3>{props.selectedLeg?.containerNumber}</h3>
              <div>
                {ssl?.nickname || ssl?.name} | {props.selectedLeg?.containerSize}
              </div>
            </td>
            <td>
              <h3>{props.selectedLeg?.legNumber}</h3>
              <div>{legStatusesToUIMappings[props.selectedLeg?.legStatus]}</div>
            </td>
            <td>
              <h3>
                {props.driver?.attributes?.firstName} {props.driver?.attributes?.lastName}
              </h3>
              <div className={`${className}__link-text`}>
                {phone}
                <PhoneButton id={phone} copyValue={phone} toolTipValue={phone} screen="admin/reactive-dual-trans" />
              </div>
            </td>
            <td>
              <h3>{importExportUIMappings[props.selectedLeg?.orderImportExport]}</h3>
              <div>{dropOrLiveToUIMappings[props.selectedLeg?.orderDeliveryType]}</div>
            </td>
            <td>
              <h3>{props.selectedLeg?.jobStops?.split(' -> ')[0]}</h3>
              <div>{formatAppointment(props.selectedLeg?.firstStopAppointmentStart)}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <table className={`${className}__matches`} data-testid="reactive-dual-trans-matches">
        <thead>
          <tr>
            <th onClick={() => toggleColumn('legNumber')}>
              Job {sortedColumn === 'legNumber' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('legStatus')}>
              Leg Status {sortedColumn === 'legStatus' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('driverName')}>
              Driver {sortedColumn === 'driverName' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('containerNumber')}>
              Container {sortedColumn === 'containerNumber' ? `(${sortDirection})` : ''}
            </th>
            <th>On-hired</th>
            <th onClick={() => toggleColumn('perDiemDueDate')}>
              Per Diem Date {sortedColumn === 'perDiemDueDate' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('firstStopName')}>
              Current Location {sortedColumn === 'firstStopName' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('lastStopName')}>
              Last Stop {sortedColumn === 'lastStopName' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn(props.useNewBundles ? 'lastStopStart' : 'returnAppointment')}>
              Return Appointment{' '}
              {sortedColumn === (props.useNewBundles ? 'lastStopStart' : 'returnAppointment')
                ? `(${sortDirection})`
                : ''}
            </th>
            <th onClick={() => toggleColumn(props.useNewBundles ? 'emptyReadyAt' : 'readyForPickupDate')}>
              Backhaul Ready Date{' '}
              {sortedColumn === (props.useNewBundles ? 'emptyReadyAt' : 'readyForPickupDate')
                ? `(${sortDirection})`
                : ''}
            </th>
          </tr>
        </thead>
        <tbody>
          {rowMatches?.filter(filterMatches).map((match) => <Row match={match} key={match.legNumber} />)}
          {props.useNewBundles && props.matchesLoading ? <LoadingSpinner className={`${className}-spinner`} /> : null}
        </tbody>
      </table>
    </div>
  ) : null;
}

export default DualInMatches;
