import type { SubscriptionContainerPayload } from '../../../SubscriptionNotification/stores';
import type { ContainerConfigurationsPayload } from '../components/SubscribeToContainers/container';

export function convertToSubscribeContainerPayload(
  containersConfigurationsPayload: ContainerConfigurationsPayload
): SubscriptionContainerPayload[] {
  const payload: SubscriptionContainerPayload[] = [];

  Object.values(containersConfigurationsPayload).forEach((item) => {
    if (item.ingate !== undefined) {
      payload.push(item.ingate);
    }
    if (item.outgate !== undefined) {
      payload.push(item.outgate);
    }
  });
  return payload;
}
