// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-input__textarea {
    padding: 20px;
    border: 1px solid var(--color-grayscale-5);
    width: 100%;
    max-width: 1371px;
    height: 200px;
    outline: none;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/SubscribeToContainers/Components/SubscribeInput/styles.css"],"names":[],"mappings":"AACE;IACE,aAAa;IACb,0CAA0C;IAC1C,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,aAAa;EACf","sourcesContent":[".subscription-input {\n  &__textarea {\n    padding: 20px;\n    border: 1px solid var(--color-grayscale-5);\n    width: 100%;\n    max-width: 1371px;\n    height: 200px;\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
