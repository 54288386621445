export interface DelayProperties {
  value: number;
  label: string;
}

export const delays: Record<number, DelayProperties> = {
  0: { value: 0, label: 'No Delay' },
  1: { value: 1, label: '1 Hour Delay' },
  2: { value: 2, label: '2 Hour Delay' },
  3: { value: 3, label: '3 Hour Delay' },
  4: { value: 4, label: '4 Hour Delay' },
  5: { value: 5, label: '5 Hour Delay' },
  6: { value: 6, label: '6 Hour Delay' },
  7: { value: 7, label: '7 Hour Delay' },
  8: { value: 8, label: '8 Hour Delay' },
  9: { value: 9, label: '9 Hour Delay' },
  10: { value: 10, label: '10 Hour Delay' },
  11: { value: 11, label: '11 Hour Delay' },
  12: { value: 12, label: '12 Hour Delay' },
  13: { value: 13, label: '13 Hour Delay' },
  14: { value: 14, label: '14 Hour Delay' },
  15: { value: 15, label: '15 Hour Delay' },
  16: { value: 16, label: '16 Hour Delay' },
  17: { value: 17, label: '17 Hour Delay' },
  18: { value: 18, label: '18 Hour Delay' },
  19: { value: 19, label: '19 Hour Delay' },
  20: { value: 20, label: '20 Hour Delay' },
  21: { value: 21, label: '21 Hour Delay' },
  22: { value: 22, label: '22 Hour Delay' },
  23: { value: 23, label: '23 Hour Delay' },
  24: { value: 24, label: '24 Hour Delay' },
  36: { value: 36, label: '36 Hour Delay' },
  48: { value: 48, label: '48 Hour Delay' },
  72: { value: 72, label: '72 Hour Delay' }
};
