import React, { ReactElement } from 'react';
import TextInput from '@client/components/TextInput/component';

import './styles.css';

interface Props {
  _handleInputChange: (value: string) => void;
  containerNumbers: string;
}

function SubscribeInput({ _handleInputChange, containerNumbers }: Props): ReactElement {
  const baseClassName = 'subscription-input';
  return (
    <TextInput
      className={`${baseClassName}__textarea`}
      isArea
      onChange={_handleInputChange}
      value={containerNumbers}
      name="Subscribe to containers"
      placeholder="Enter container numbers here separated by spaces, commas, or hard returns."
    />
  );
}

export default SubscribeInput;
