import React, { MouseEvent, useEffect, useState } from 'react';
import DialogCard from '@client/components/DialogCard';
import Button from '@client/components/Button';

import './styles.css';

interface Props {
  containerNumber: string;
  onCancel: () => void;
  onConfirm: () => void;
}

function SubscriptionNotificationDeleteDialog(props: Props) {
  const baseClassName = 'dialog';
  const [containerNumber, setContainerNumbers] = useState<string>('');

  useEffect(() => {
    setContainerNumbers(props.containerNumber);
  }, []);

  const handleConfirm = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onConfirm();
  };

  return (
    <DialogCard className={baseClassName} onClose={props.onCancel}>
      <header className={`${baseClassName}__header`}>
        <div className={`${baseClassName}__title`}>Deleting Subscription</div>
        <div className={`${baseClassName}__text`}>
          Are you sure you want to delete notification for container {containerNumber}
        </div>
      </header>
      <div className={`${baseClassName}__actions`}>
        <Button label="Cancel" onClick={props.onCancel} />
        <Button theme="2" label="Confirm" onClick={handleConfirm} />
      </div>
    </DialogCard>
  );
}

export default SubscriptionNotificationDeleteDialog;
