import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getAuthToken } from '@client/utils/auth';
import { sendSuccessNotification, sendFailureNotification } from '@client/_blessed/store/utils/toastNotifications';
import { SubscriptionType } from '@drayalliance/types';

export interface SubscriptionNotifications {
  uuid: string;
  requestorUUID: string;
  requestorUuid: string;
  entityId: string;
  entityType: string;
  containerNumber: string;
  type: string;
  data: any;
  config: string;
  lastSentAt: Date | null;
  isProcessing: boolean;
  processedCount: number;
  sentCount: number;
  expiresAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface SubscriptionContainerPayload {
  uuid?: string;
  type: SubscriptionType;
  data: {
    containerNumber: string;
    appointmentTimeOffset?: number;
  };
  config: {
    slack: {
      channelIds: string[];
    };
  };
  expiresAt?: Date;
}

export interface DeletedItems {
  uuid: string;
  deletedAt: Date;
}

export interface DeleteErrorItems {
  status: number;
  uuid: string;
  errors: [
    {
      message: string;
      reason: string;
      field: string;
    }
  ];
}

export const sliceId = 'subscriptionNotification';

const initialState = {
  error: false,
  loading: false,
  sorting: {
    field: 'containerNumber',
    direction: 'ASC'
  },
  showSubscribeComponent: false,
  showSubscriptionEditComponent: false,
  uuidForEdit: ''
};

export const fetchAllSubscriptionNotifications = createAsyncThunk('subscriptionNotification/fetchAll', async () => {
  const authHeader = getAuthToken();

  const options: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin'
  };

  const url = api('/subscriptions');

  try {
    const response = await request(url, options);
    return response.subscriptions;
  } catch (err) {
    const message = 'Unable to fetch subscription notifications';
    sendFailureNotification(message, 'top-left');
  }
});

export const fetchSubscriptionNotificationsByType = createAsyncThunk(
  'subscriptionNotification/fetchByType',
  async (type: string) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/subscriptions?types=${type}`);

    try {
      const response = await request(url, options);
      return response.subscriptions;
    } catch (err) {
      const message = 'Unable to fetch subscription notifications';
      sendFailureNotification(message, 'top-left');
    }
  }
);

export const fetchSubscriptionNotificationByUuids = createAsyncThunk(
  'subscriptionNotification/fetchByUuids',
  async (uuids: string[]) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/subscriptions?uuids=${uuids}`);

    try {
      const response = await request(url, options);
      return response.subscriptions;
    } catch (err) {
      const message = 'Unable to fetch subscription notifications';
      sendFailureNotification(message, 'top-left');
    }
  }
);

export const subscribeToContainer = createAsyncThunk(
  'subscriptionNotification/createSubscriptionNotifications',
  async (payload: SubscriptionContainerPayload[]) => {
    const authHeader = getAuthToken();
    const body = JSON.stringify({ subscriptions: payload });

    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin',
      body
    };

    const url = api(`/subscription-notifications`);

    try {
      const response = await request(url, options);
      return response;
    } catch (err) {
      const { message } = (err as Error) || {};
      sendFailureNotification(message);
    }
  }
);

export const updateSubscribedContainers = createAsyncThunk(
  'subscriptionNotification/updateSubscriptionNotifications',
  async (payload: SubscriptionContainerPayload[]) => {
    const authHeader = getAuthToken();
    const body = JSON.stringify({ subscriptions: payload });

    const options: RequestInit = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin',
      body
    };

    const url = api(`/subscription-notifications`);

    try {
      const response = await request(url, options);
      return response;
    } catch (err) {
      const { message } = (err as Error) || {};
      sendFailureNotification(message);
    }
  }
);

export const deleteSubscriptionNotifications = createAsyncThunk(
  'subscriptionNotification/deleteBulk',
  async (uuids: { uuid: string }[]) => {
    if (!uuids.length) {
      return;
    }
    const url = api('/subscription-notifications');
    const body = JSON.stringify({ subscriptions: uuids });
    const options = { method: 'DELETE', body };

    try {
      const response = (await request(url, options)) as any;
      return response.subscriptions;
    } catch (err) {
      const { message } = (err as Error) || {};
      sendFailureNotification(message || 'Unable to delete subscription');
    }
  }
);

export const subscriptionNotificationAdapter = createEntityAdapter<any>({
  selectId: (subscriptionNotifications: SubscriptionNotifications) => subscriptionNotifications?.uuid
});

export const slice = createSlice({
  name: sliceId,
  initialState: subscriptionNotificationAdapter.getInitialState(initialState),
  reducers: {
    updateSort: (state, action) => {
      if (action.payload === state.sorting.field) {
        state.sorting.direction = state.sorting.direction === 'ASC' ? 'DESC' : 'ASC';
      } else {
        state.sorting = {
          field: action.payload,
          direction: 'ASC'
        };
      }
    },
    setUuidForEdit: (state, action) => {
      state.uuidForEdit = action.payload;
    },
    setSubscribeComponent: (state, action) => {
      state.showSubscribeComponent = action.payload;
    },
    setShowSubscriptionEditComponent: (state, action) => {
      state.showSubscriptionEditComponent = action.payload;
    }
  },
  extraReducers: (builder) => {
    // GET
    builder.addCase(fetchAllSubscriptionNotifications.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];
      if (data) {
        subscriptionNotificationAdapter.setAll(state, data);
      }
    });
    builder.addCase(fetchSubscriptionNotificationByUuids.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];
      if (data) {
        subscriptionNotificationAdapter.setMany(state, data);
      }
    });
    builder.addCase(fetchSubscriptionNotificationsByType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubscriptionNotificationsByType.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];
      if (data) {
        subscriptionNotificationAdapter.setAll(state, data);
      }
    });

    // POST
    builder.addCase(subscribeToContainer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(subscribeToContainer.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];
      if (data.length > 0) {
        subscriptionNotificationAdapter.upsertMany(state, data);
        const msg = 'Successfully subscribed to new container(s)';
        state.showSubscribeComponent = false;
        return sendSuccessNotification(msg);
      }
    });

    // PATCH
    builder.addCase(updateSubscribedContainers.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];

      if (data.length > 0) {
        subscriptionNotificationAdapter.upsertMany(state, data);
        const msg = 'Successfully updated container.';
        state.showSubscriptionEditComponent = false;
        return sendSuccessNotification(msg);
      }
    });

    // DELETE
    builder.addCase(deleteSubscriptionNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSubscriptionNotifications.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload || [];
      const errors = data.filter((d: DeleteErrorItems) => d.status === 400 || d.status === 404);

      if (errors.length > 0) {
        const msg = 'Subscription does not exist or previously deleted!';
        return sendFailureNotification(msg);
      }
      if (data) {
        const uuids = data.map((d: DeletedItems) => d.uuid);
        subscriptionNotificationAdapter.removeMany(state, uuids);
        const msg = 'Subscription(s) Deleted!';
        sendSuccessNotification(msg);
      }
    });
  }
});

export const { updateSort, setSubscribeComponent, setShowSubscriptionEditComponent, setUuidForEdit } = slice.actions;

export default slice;
