import React, { useEffect, useState } from 'react';
import EditableField from '@client/components/EditableField';
import './style.css';
import { logAnalyticsClick } from '@client/utils/analytics';
import { DualIngateSmartMatch } from '@client/_blessed/models';
import { ContainerOnStatusEntity, GeneratorLeg } from '@client/_blessed/store/entities';
import { DriverJob } from '@client/_blessed/models/ReactiveDualTrans';
import DualInMatchesSidebar from './features/DualInMatchesSidebar';
import DriverJobsTable from './features/DriverJobsTable';

interface ReactiveDualTransProps {
  tableData: any;
  driverOptions: any[];
  matches: DualIngateSmartMatch[];
  generatorMatches: GeneratorLeg[];
  driversById: any;
  departmentsById: any;
  tableSort: any;
  matchSort: any;
  locationOptions: { value: string }[];
  useNewBundles: boolean;
  onHireStatusesByContainer: Record<string, ContainerOnStatusEntity>;
  onLoadDriverJobs: (params: { driverID: string }) => void;
  onTableSort: (value: any) => void;
  onMatchSort: (value: any) => void;
  onLoadMatches: (params: { legID: string; legNumber: number }) => void;
  onResetMatches: () => void;
}

function ReactiveDualTrans(props: ReactiveDualTransProps) {
  const [selectedDriver, selectDriver] = useState<string>('');
  const [selectedLeg, selectLeg] = useState<DriverJob | null>(null);
  const className = 'ReactiveDualTrans';

  const driver = props.driversById[selectedDriver];
  const departmentId = driver?.relationships?.departments?.data[0]?.id;
  const department = props.departmentsById[departmentId];

  const tableSortDirection = props.tableSort.direction;
  const sortedColumn = props.tableSort.column;

  const driverNameFormatter = (driverUuid: string) => {
    const localDriver = props.driversById[driverUuid];
    if (!localDriver) {
      return null;
    }
    return `${localDriver.attributes.firstName} ${localDriver.attributes.lastName}`;
  };

  const toggleColumn = (column: string) => {
    props.onTableSort({
      column,
      direction: (tableSortDirection || '').toUpperCase() === 'ASC' ? 'DESC' : 'ASC'
    });
  };

  const handleCloseDrawer = (e: any) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    selectLeg(null);
    props.onResetMatches();
  };

  useEffect(() => {
    if (selectedDriver) {
      logAnalyticsClick('select driver', 'admin/reactive-dual-trans', { driver: driverNameFormatter(selectedDriver) });
      props.onLoadDriverJobs({ driverID: selectedDriver });
    }
  }, [selectedDriver]);

  useEffect(() => {
    if (selectedLeg) {
      logAnalyticsClick('select leg', 'admin/reactive-dual-trans', { leg: selectedLeg.legNumber });
      props.onLoadMatches({ legID: selectedLeg.legUuid, legNumber: selectedLeg.legNumber });
    }
  }, [selectedLeg]);

  return (
    <div className={className}>
      <div className={`${className}-title`}>Reactive Dual Trans</div>
      <div className={`${className}-carrier`}>
        Carrier: {department?.nickname || department?.name || 'Department not found'}
      </div>
      <EditableField
        className={`${className}-dropdown`}
        type="dropdown"
        editable
        placeholder="Select Driver"
        value={selectedDriver}
        onChange={(val: string) => selectDriver(val)}
        displayFormatter={driverNameFormatter}
        inputProps={{
          options: props.driverOptions
        }}
      />
      {selectedDriver && (
        <DriverJobsTable
          tableData={props.tableData}
          toggleColumn={toggleColumn}
          sortedColumn={sortedColumn}
          tableSortDirection={tableSortDirection}
          departmentsById={props.departmentsById}
          selectLeg={selectLeg}
        />
      )}
      <DualInMatchesSidebar
        selectedLeg={selectedLeg}
        onCloseDrawer={handleCloseDrawer}
        departmentsById={props.departmentsById}
        driver={driver}
        matches={props.matches}
        generatorMatches={props.generatorMatches}
        matchSort={props.matchSort}
        onMatchSort={props.onMatchSort}
        locationOptions={props.locationOptions}
        useNewBundles={props.useNewBundles}
        onHireStatusesByContainer={props.onHireStatusesByContainer}
      />
    </div>
  );
}

export default ReactiveDualTrans;
