import React from 'react';
import { isEmpty } from 'lodash';
import type {
  ContainerConfigurationsPayload,
  HandleContainerConfigUpdate,
  HandleRemoveAddedContainers
} from '../../container';
import ContainerConfigurationsListItems from '../ContainerConfigurationsListItems';

import './styles.css';

interface Props {
  containersConfigurations: ContainerConfigurationsPayload;
  _handleContainerConfigUpdate: HandleContainerConfigUpdate;
  _handleRemoveAddedContainers: HandleRemoveAddedContainers;
}

function ContainerConfigurationsList({
  containersConfigurations,
  _handleContainerConfigUpdate,
  _handleRemoveAddedContainers
}: Props) {
  const baseClassName = 'container-configurations';

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__divider`} />
      <header className={`${baseClassName}__header`}>
        <h3>Configure your container subscriptions</h3>
        <p className={`${baseClassName}__sub-text`}>
          Select your appointment type notification and booking delay preferences.
        </p>
      </header>
      {!isEmpty(containersConfigurations) &&
        Object.keys(containersConfigurations).map((containerNumber) => (
          <ContainerConfigurationsListItems
            key={containerNumber}
            containerConfigurations={containersConfigurations[containerNumber]}
            containerNumber={containerNumber}
            _handleContainerConfigUpdate={_handleContainerConfigUpdate}
            _handleRemoveAddedContainers={_handleRemoveAddedContainers}
          />
        ))}
    </div>
  );
}

export default ContainerConfigurationsList;
