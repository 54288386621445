import React, { ReactElement, useEffect, useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { appActionContext, appContext } from '@client/components/App/contexts';
import { appointmentBookingRetrieveOrders } from '@client/state/resources/orders/actions';
import { drayAllianceDepartmentUUID } from '@client/constants';
import {
  fetchTerminalScraperConfigurations,
  fetchUsersForDepartment,
  selectAllTerminalScraperConfigurations,
  selectTerminalUuidMap,
  selectUsersByUuid
} from '@client/_blessed/store/entities';
import LoadingSpinner from '@client/components/LoadingSpinner/component';
import { serializeContainersToRetrieveOrders } from '@client/utils/serializedContainersToRetrieveOrders';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { fetchSubscriptionNotificationsByType, setSubscribeComponent } from '../../SubscriptionNotification/stores';
import {
  selectAllSubscriptionNotifications,
  selectUuidForEdit,
  selectShowEditSubscriptionComponent,
  selectShowSubscribeComponent,
  selectSortedSubscriptionNotificationData
} from '../../SubscriptionNotification/stores/selectors';
import SubscriptionTable from './components/SubscriptionTable';
import SupportedTerminalBooking from './components/SupportedTerminalBooking/component';
import SubscribeToContainers from './components/SubscribeToContainers/container';
import SubscriptionEdit from './components/SubscriptionEdit/component';
import BackButton from './components/BackButton/component';

import './styles.css';

export interface SubscriptionNotificationUiData {
  uuid: string;
  containerNumber: string;
  terminal?: string;
  perDiemDueDate?: string | null;
  lfd?: string | null;
  bookingDelay?: number;
  subscriptions?: string;
  subscriber?: string;
  lastChecked?: string | Date;
}

function AppointmentBooking(): ReactElement {
  const baseClassName = 'appointment-booking';
  const dispatch = useAppDispatch();
  const appDispatch = useContext(appActionContext);
  const featureFlags = useFlags();
  /**
   * FF JSON
   * {
   *  "event_ingate_appointment": true,
   *  "event_outgate_appointment": tru
   * }
   */
  const { subscriptionNotificationEventTypes } = featureFlags;

  const { state: appState } = useContext(appContext);

  const [searchQuery, setSearchQuery] = useState('');

  const usersMap = useSelector(selectUsersByUuid);
  const ordersMap = appState['order.id'];

  const ordersLoading = !!appState['fetch.orders.isFetching'];
  const usersLoading = !!appState['fetch.users.isFetching'];
  const fetchUsersErrors = !!appState['fetch.users.errors'];
  const fetchOrdersErrors = !!appState['fetch.orders.errors'];

  const allSubscriptionNotifications = useSelector(selectAllSubscriptionNotifications);
  const terminalScraperConfigurations = useSelector(selectAllTerminalScraperConfigurations);

  const terminalUuidMap = useSelector(selectTerminalUuidMap);
  const sortedSubscriptionNotifications = useSelector(
    selectSortedSubscriptionNotificationData({ usersMap, terminalUuidMap, ordersMap, query: searchQuery })
  );
  const showSubscribeComponent = useSelector(selectShowSubscribeComponent);
  const showEditSubscriptionComponent = useSelector(selectShowEditSubscriptionComponent);
  const currentUuidForEdit = useSelector(selectUuidForEdit);

  useEffect(() => {
    const subscriptionsTypeToFetch = Object.keys(subscriptionNotificationEventTypes)
      .filter((key) => subscriptionNotificationEventTypes[key])
      .join(',');

    const subscriptionNotificationPromise = dispatch(fetchSubscriptionNotificationsByType(subscriptionsTypeToFetch));
    const terminalConfigPromise = dispatch(fetchTerminalScraperConfigurations());
    const usersPromise = dispatch(fetchUsersForDepartment(drayAllianceDepartmentUUID));

    return () => {
      subscriptionNotificationPromise.abort();
      terminalConfigPromise.abort();
      usersPromise.abort();
    };
  }, [subscriptionNotificationEventTypes]);

  // fetches appState
  useEffect(() => {
    /**
     * Refactor back-end to have unique value only
     */
    const containerNumbers = allSubscriptionNotifications.reduce((acc: Record<string, string>, asn) => {
      acc[asn.containerNumber] = asn.containerNumber;
      return acc;
    }, {});

    const serializedFilter = serializeContainersToRetrieveOrders(Object.keys(containerNumbers));
    // Fetching without containers in filter will cause to hub-service
    if (Object.keys(containerNumbers).length > 0) {
      appDispatch(appointmentBookingRetrieveOrders(`filters=${serializedFilter}`));
    }
  }, [allSubscriptionNotifications]);

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);
  };

  const debouncedSearchQuery = useCallback(debounce(handleSearchQuery, 1000), []);

  const handleBackClick = () => {
    dispatch(setSubscribeComponent(false));
  };

  const loadMainComponents = () => {
    /**
     * Handle whether or not fetchingUser or Order has error
     */
    if (ordersLoading || usersLoading) {
      return (
        <div className={`${baseClassName}__loader-wrapper`}>
          <LoadingSpinner className={`${baseClassName}__loading`} />
          <p>Loading Data...</p>
        </div>
      );
    }

    if (fetchOrdersErrors || fetchUsersErrors) {
      return (
        <div className={`${baseClassName}__error-wrapper`}>
          <p>Failed to load data, please refresh page</p>
        </div>
      );
    }

    return (
      <div className={`${baseClassName}__content-wrapper`}>
        {showSubscribeComponent && (
          <div className={`${baseClassName}__title-input`}>
            <SubscribeToContainers />
          </div>
        )}
        {showEditSubscriptionComponent && <SubscriptionEdit uuid={currentUuidForEdit} />}
        {!showSubscribeComponent && !showEditSubscriptionComponent && (
          <SubscriptionTable
            searchQuery={searchQuery}
            handleQuery={debouncedSearchQuery}
            subscriptionNotifications={sortedSubscriptionNotifications}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <Helmet title="Appointment Subscriptions" />
      <div className={baseClassName}>
        <BackButton showComponentCondition={showSubscribeComponent} handleOnClick={handleBackClick} />
        {!showEditSubscriptionComponent && <h1>Appointment Subscriptions</h1>}
        {!showSubscribeComponent && !showEditSubscriptionComponent && (
          <SupportedTerminalBooking supportedTerminals={terminalScraperConfigurations} />
        )}
        {loadMainComponents()}
      </div>
    </>
  );
}

export default AppointmentBooking;
