import React, { ReactElement, useContext } from 'react';
import moment from 'moment-timezone';
import TrashIcon from '@components/deprecatedTookit/icons/fa/regular/TrashAltIcon';
import BellIcon from '@components/deprecatedTookit/icons/fa/solid/BellIcon';
import PenIcon from '@components/deprecatedTookit/icons/fa/regular/PenIcon';
import { SubscriptionType } from '@drayalliance/types';
import { appUIActionContext } from '@client/components/App/contexts';
import { toggleAppModal } from '@client/state/ui/actions';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import {
  deleteSubscriptionNotifications,
  setShowSubscriptionEditComponent,
  setUuidForEdit
} from '../../../../SubscriptionNotification/stores';
import SubscriptionNotificationDeleteDialog from '../DeleteDialog/component';
import type { SubscriptionNotificationUiData } from '../../container';
import { subscriptionType } from '../../../../SubscriptionNotification/stores/selectors';
import { delays } from '../../../../SubscriptionNotification/stores/delays';

import './styles.css';

interface Props {
  subscription: SubscriptionNotificationUiData;
}

function SubscriptionListItems(props: Props): ReactElement {
  const baseClassName = 'subscription-table-item';
  const dispatch = useAppDispatch();
  const appDispatchUI = useContext(appUIActionContext);

  const { containerNumber, uuid, perDiemDueDate, lfd, subscriber, lastChecked, bookingDelay, subscriptions, terminal } =
    props.subscription;

  const handleDelete = () => {
    dispatch(deleteSubscriptionNotifications([{ uuid }]));
  };

  const handleReasonDialogClose = () => {
    appDispatchUI(toggleAppModal(false));
  };

  const handleReasonDialogConfirm = () => {
    appDispatchUI(toggleAppModal(false));
    handleDelete();
  };

  const handleFreeFlowOrdersDialog = () => {
    appDispatchUI(
      toggleAppModal(
        true,
        <SubscriptionNotificationDeleteDialog
          containerNumber={containerNumber}
          onCancel={handleReasonDialogClose}
          onConfirm={handleReasonDialogConfirm}
        />
      )
    );
  };

  const handleEditButtonClick = () => {
    dispatch(setUuidForEdit(uuid));
    dispatch(setShowSubscriptionEditComponent(true));
  };

  return (
    <tr className={baseClassName}>
      <td>{containerNumber}</td>
      <td>
        {subscriptions === subscriptionType[SubscriptionType.EventIngateAppointment]
          ? 'All accepting terminals'
          : terminal}
      </td>
      <td>{lfd}</td>
      <td>{perDiemDueDate}</td>
      <td>
        {subscriber}
        <BellIcon className={`${baseClassName}__icon`} />
      </td>
      <td>{subscriptions}</td>
      <td>
        {delays?.[bookingDelay as number]?.label}{' '}
        <button
          className={`${baseClassName}__edit-button ${
            subscriptions === subscriptionType[SubscriptionType.EventOutgateAppointment]
              ? `${baseClassName}__edit-button--disabled`
              : ''
          }`}
          type="button"
          onClick={handleEditButtonClick}
          disabled={subscriptions === subscriptionType[SubscriptionType.EventOutgateAppointment]}
          aria-label="Edit subscription"
        >
          <PenIcon className={`${baseClassName}__icon`} />
        </button>
      </td>
      <td>{lastChecked instanceof Date ? moment(lastChecked).format('MM/DD/YYYY HH:mm:ss') : lastChecked}</td>
      <td>
        <button
          className={`${baseClassName}__delete-btn`}
          type="button"
          onClick={handleFreeFlowOrdersDialog}
          aria-label="Delete subscrition"
        >
          <TrashIcon />
        </button>
      </td>
    </tr>
  );
}

export default React.memo(SubscriptionListItems);
