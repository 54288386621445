// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-configurations__header {
    margin-top: 30px;
  }

    .container-configurations__header > h3 {
      font-weight: 600;
      font-size: 18px;
    }
  .container-configurations__sub-text {
    margin: 0;
  }
  .container-configurations__divider {
    display: block;
    height: 1px;
    width: 100%;
    margin-top: 30px;
    background: #d9d9d9;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/SubscribeToContainers/Components/ContainerConfigurationsList/styles.css"],"names":[],"mappings":"AACE;IACE,gBAAgB;EAMlB;;IAJE;MACE,gBAAgB;MAChB,eAAe;IACjB;EAGF;IACE,SAAS;EACX;EAEA;IACE,cAAc;IACd,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,mBAAmB;EACrB","sourcesContent":[".container-configurations {\n  &__header {\n    margin-top: 30px;\n\n    & > h3 {\n      font-weight: 600;\n      font-size: 18px;\n    }\n  }\n\n  &__sub-text {\n    margin: 0;\n  }\n\n  &__divider {\n    display: block;\n    height: 1px;\n    width: 100%;\n    margin-top: 30px;\n    background: #d9d9d9;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
