// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactiveDualTrans {
  padding: 28px 31px;
  width: 100%;
  height: 100%;
}

  .ReactiveDualTrans-title {
    font-size: 20px;
    font-weight: 500;
  }

  .ReactiveDualTrans-dropdown {
    width: 300px;
  }

  .ReactiveDualTrans-dropdown .react-dropdown-select-dropdown {
      width: 300px;
    }

  .ReactiveDualTrans-carrier {
    margin: 15px 0;
  }`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/ReactiveDualTrans/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AAkBd;;EAhBE;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,YAAY;EAKd;;EAHE;MACE,YAAY;IACd;;EAGF;IACE,cAAc;EAChB","sourcesContent":[".ReactiveDualTrans {\n  padding: 28px 31px;\n  width: 100%;\n  height: 100%;\n\n  &-title {\n    font-size: 20px;\n    font-weight: 500;\n  }\n\n  &-dropdown {\n    width: 300px;\n\n    .react-dropdown-select-dropdown {\n      width: 300px;\n    }\n  }\n\n  &-carrier {\n    margin: 15px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
