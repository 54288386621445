export const sortBy = (sort: { column: string; direction: 'ASC' | 'DESC' }) => (a: any, b: any) => {
  const aValue = (a[sort.column] || '').toString();
  const bValue = (b[sort.column] || '').toString();

  if (sort.direction.toUpperCase() === 'ASC') {
    return aValue < bValue ? -1 : 1;
  }

  return aValue > bValue ? -1 : 1;
};

export const sortNullDateColumnsLast = (arr: any[], column: string): any[] => {
  const hasDate = arr.filter((item) => item[column]);
  const noDate = arr.filter((item) => !item[column]);
  return [...hasDate, ...noDate];
};

export const sortByAttributes = (sort: { column: string; direction: 'ASC' | 'DESC' }) => (a: any, b: any) => {
  const aValue = (a.attributes[sort.column] || '').toString();
  const bValue = (b.attributes[sort.column] || '').toString();

  if (sort.direction.toUpperCase() === 'ASC') {
    return aValue < bValue ? -1 : 1;
  }

  return aValue > bValue ? -1 : 1;
};

export const sortNullDateAttributeColumnsLast = (arr: any[], column: string): any[] => {
  const hasDate = arr.filter((item) => item.attributes[column]);
  const noDate = arr.filter((item) => !item.attributes[column]);
  return [...hasDate, ...noDate];
};
