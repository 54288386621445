import React from 'react';

import './styles.css';

interface Props {
  handleOnClick: () => void;
  showComponentCondition: boolean;
}

function BackButton({ handleOnClick, showComponentCondition }: Props) {
  const baseClassName = 'back-button';

  return showComponentCondition ? (
    <button className={`${baseClassName}`} type="button" onClick={handleOnClick}>
      <span className={`${baseClassName}__arrow`}>&#x2190;</span> back
    </button>
  ) : null;
}

export default BackButton;
