import React, { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '@client/components/Checkbox/component';
import { SubscriptionType } from '@drayalliance/types';
import Button from '@client/components/Button/component';
import DropdownSelectInput from '@client/components/DropdownSelectInput/component';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import type { RootState } from '@client/reduxProvider';
import {
  setShowSubscriptionEditComponent,
  SubscriptionContainerPayload,
  SubscriptionNotifications,
  updateSubscribedContainers
} from '../../../../SubscriptionNotification/stores';
import {
  selectShowEditSubscriptionComponent,
  selectSubscriptionById,
  subscriptionType
} from '../../../../SubscriptionNotification/stores/selectors';
import BackButton from '../BackButton/component';
import { DelayProperties, delays } from '../../../../SubscriptionNotification/stores/delays';

import './styles.css';

interface Props {
  uuid: string;
}

function SubscriptionEdit({ uuid }: Props) {
  const baseClassName = 'subscription-edit';
  const dispatch = useAppDispatch();

  const selectedSubscription: SubscriptionNotifications = useSelector((state: RootState) =>
    selectSubscriptionById(state, uuid)
  );
  const showEditSubscriptionComponent = useSelector(selectShowEditSubscriptionComponent);
  const [subscriptionForUpdate, setSubscriptionForUpdate] = useState<SubscriptionContainerPayload>(
    {} as SubscriptionContainerPayload
  );

  const { data, type, containerNumber } = selectedSubscription || {};
  const existingDelay = delays[data?.appointmentTimeOffset] || delays[0];

  const handleDelayUpdate = (value: number) => {
    const updated = {
      uuid,
      type: type as SubscriptionType,
      data: {
        containerNumber,
        appointmentTimeOffset: value
      },
      config: {
        slack: {
          channelIds: []
        }
      }
    };
    updated.data.appointmentTimeOffset = value;
    setSubscriptionForUpdate(updated);
  };

  const handleBackAndCancelClick = () => {
    dispatch(setShowSubscriptionEditComponent(false));
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(updateSubscribedContainers([subscriptionForUpdate]));
  };

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__header`}>
        <BackButton handleOnClick={handleBackAndCancelClick} showComponentCondition={showEditSubscriptionComponent} />
        <h2>Edit Subscription</h2>
      </div>
      <div className={`${baseClassName}__configs-content`}>
        <div className={`${baseClassName}__container-number`}>{data.containerNumber}</div>
        <div className={`${baseClassName}__configs`}>
          <Checkbox label={subscriptionType[type as SubscriptionType]} checked={!!type} disabled />
          <DropdownSelectInput
            multi={false}
            values={[existingDelay]}
            options={Object.values(delays)}
            valueField="value"
            labelField="label"
            onChange={(value: DelayProperties[]) => handleDelayUpdate(value[0]?.value)}
          />
        </div>
      </div>
      <div className={`${baseClassName}__cta-group`}>
        <Button label="Cancel" onClick={handleBackAndCancelClick} />
        <Button label="Submit" theme="2" onClick={handleSubmit} />
      </div>
    </div>
  );
}

export default SubscriptionEdit;
