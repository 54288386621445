import React from 'react';
import CopyButton from '@client/components/CopyButton';
import {
  dropOrLiveToUIMappings,
  importExportUIMappings,
  legStatusesToUIMappings,
  legTypesToUIMappings
} from '@client/constants';
import './style.css';
import { formatAppointment } from '@client/_blessed/components/features/Admin/features/ReactiveDualTrans/features/utils';

interface DriverJobsTableProps {
  tableData: any;
  toggleColumn: (column: string) => void;
  tableSortDirection: string;
  sortedColumn: string;
  departmentsById: any;
  selectLeg: (val: any) => void;
}

function DriverJobsTable({
  tableData,
  toggleColumn,
  tableSortDirection,
  sortedColumn,
  departmentsById,
  selectLeg
}: DriverJobsTableProps) {
  const className = `DriverJobsTable`;
  return (
    <table className={className}>
      <thead>
        <tr>
          <th onClick={() => toggleColumn('legNumber')}>
            Job # {sortedColumn === 'legNumber' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('legStatus')}>
            Leg Status {sortedColumn === 'legStatus' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('containerNumber')}>
            Container {sortedColumn === 'containerNumber' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('orderImportExport')}>
            Order Type {sortedColumn === 'orderImportExport' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('orderDeliveryType')}>
            Delivery Type {sortedColumn === 'orderDeliveryType' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('legType')}>
            Leg Type {sortedColumn === 'legType' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('jobStops')}>
            Job Stops {sortedColumn === 'jobStops' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('firstStopAppointmentStart')}>
            First stop appt time {sortedColumn === 'firstStopAppointmentStart' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('lastStopAppointmentStart')}>
            Last stop appt time {sortedColumn === 'lastStopAppointmentStart' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('lastFreeDay')}>
            LFD / Cutoff Date {sortedColumn === 'lastFreeDay' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('perDiemDueDate')}>
            Perdiem Due {sortedColumn === 'perDiemDueDate' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('steamShippingLineAccountUuid')}>
            SSL {sortedColumn === 'steamShippingLineAccountUuid' ? `(${tableSortDirection})` : ''}
          </th>
          <th onClick={() => toggleColumn('containerSize')}>
            Size {sortedColumn === 'containerSize' ? `(${tableSortDirection})` : ''}
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((job: any) => {
          const department = departmentsById[job.steamShippingLineAccountUuid];
          let clickHandler = (): void => undefined;
          let rowClass = '';
          if (job.isOutgate) {
            clickHandler = () => selectLeg(job);
            rowClass = `${className}__actionable-row`;
          }
          return (
            <tr key={job.legUuid} className={rowClass} onClick={clickHandler}>
              <td>
                {job.legNumber}
                <CopyButton value={job.legNumber} />
              </td>
              <td>{legStatusesToUIMappings[job.legStatus]}</td>
              <td>
                {job.containerNumber}
                <CopyButton value={job.containerNumber} />
              </td>
              <td>{importExportUIMappings[job.orderImportExport]}</td>
              <td>{dropOrLiveToUIMappings[job.orderDeliveryType]}</td>
              <td>{legTypesToUIMappings[job.legType]}</td>
              <td>{job.jobStops}</td>
              <td>{formatAppointment(job.firstStopAppointmentStart)}</td>
              <td>{formatAppointment(job.lastStopAppointmentStart)}</td>
              <td>{job.lastFreeDay}</td>
              <td>{job.perDiemDueDate}</td>
              <td>{department?.nickname || department?.name}</td>
              <td>{job.containerSize}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default DriverJobsTable;
