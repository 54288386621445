import { Helmet } from 'react-helmet';
import { useLogDualTransPageView, useReactiveDualTrans } from './hooks';
import Component from './component';

function Container() {
  const reactiveDualTrans = useReactiveDualTrans();
  useLogDualTransPageView();

  return (
    <>
      <Helmet title="Reactive Dual Trans" />
      <Component
        tableData={reactiveDualTrans.tableData}
        onLoadDriverJobs={reactiveDualTrans.loadTableData}
        onTableSort={reactiveDualTrans.handleTableSort}
        onMatchSort={reactiveDualTrans.handleMatchSort}
        onLoadMatches={reactiveDualTrans.loadMatches}
        onResetMatches={reactiveDualTrans.resetMatches}
        driverOptions={reactiveDualTrans.driverOptions}
        driversById={reactiveDualTrans.driversById}
        departmentsById={reactiveDualTrans.departmentsById}
        tableSort={reactiveDualTrans.tableSort}
        matchSort={reactiveDualTrans.matchSort}
        matches={reactiveDualTrans.matches}
        generatorMatches={reactiveDualTrans.generatorMatches}
        locationOptions={reactiveDualTrans.locationOptions}
        useNewBundles={reactiveDualTrans.useNewBundles}
        onHireStatusesByContainer={reactiveDualTrans.onHireStatusesByContainer}
      />
    </>
  );
}

export default Container;
