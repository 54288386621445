// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog {
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  margin: auto;
  padding: 35px;
  width: 600px;
}

  .dialog > * {
    margin: 15px 0;
  }

  .dialog__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-left: auto;
  }

  .dialog__header {
    display: grid;
    margin: 10px 0 0;
    row-gap: 15px;
  }

  .dialog__title {
    font-size: 2.2rem;
    font-weight: 600;
  }

  .dialog__text {
    font-size: 1.05rem;
  }

  .dialog__container-values {
    display: flex;
    flex-direction: row;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/DeleteDialog/styles.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,YAAY;AAgCd;;EA9BE;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB","sourcesContent":[".dialog {\n  flex-direction: column;\n  align-items: flex-start;\n  height: auto;\n  margin: auto;\n  padding: 35px;\n  width: 600px;\n\n  & > * {\n    margin: 15px 0;\n  }\n\n  &__actions {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    column-gap: 10px;\n    margin-left: auto;\n  }\n\n  &__header {\n    display: grid;\n    margin: 10px 0 0;\n    row-gap: 15px;\n  }\n\n  &__title {\n    font-size: 2.2rem;\n    font-weight: 600;\n  }\n\n  &__text {\n    font-size: 1.05rem;\n  }\n\n  &__container-values {\n    display: flex;\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
