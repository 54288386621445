import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import getInitialAsyncSliceState from '@client/_blessed/store/utils/getInitialAsyncAtomState';
import {
  loadTableDataAction,
  retrieveDriverJobsAction,
  retrieveDriversAction,
  retrieveDualIngateGeneratorLegMatches,
  retrieveDualInMatchesAction
} from './asyncActions';

export const sliceId = 'reactiveDualTransPage';

export interface ReactiveDualTransPageState {
  driverJobs: any;
  matches: any;
  generatorMatches: any;
  drivers: any;
  tableSort: any;
  matchSort: any;
  loading: boolean;
  tableDataLoading: number;
}

export const driverAdapter = createEntityAdapter({
  selectId: (item: any) => item.id
});

const INITIAL_TABLE_SORT = {
  column: 'firstStopAppointmentStart',
  direction: 'ASC'
};
const INITIAL_MATCH_SORT = {
  column: 'perDiemDueDate',
  direction: 'ASC'
};

function getInitialState(state: any = {}): ReactiveDualTransPageState {
  state.driverJobs = getInitialAsyncSliceState();
  state.matches = getInitialAsyncSliceState();
  state.generatorMatches = getInitialAsyncSliceState();
  state.drivers = driverAdapter.getInitialState();
  state.tableSort = INITIAL_TABLE_SORT;
  state.matchSort = INITIAL_MATCH_SORT;
  state.loading = true;
  return state;
}

export default createSlice({
  name: sliceId,
  initialState: getInitialState(),
  reducers: {
    // eslint-disable-next-line no-unused-vars
    reset: (state) => getInitialState(state),
    resetMatches: (state) => {
      state.matches = getInitialAsyncSliceState();
      state.matchSort = INITIAL_MATCH_SORT;
    },
    resetGeneratorMatches: (state) => {
      state.generatorMatches = getInitialAsyncSliceState();
      state.matchSort = INITIAL_MATCH_SORT;
    },
    sortTable: (state, action) => {
      state.tableSort = action.payload;
    },
    sortMatches: (state, action) => {
      state.matchSort = action.payload;
    }
  },
  extraReducers: (builder) => {
    // drivers
    builder.addCase(retrieveDriversAction.pending, (state) => {
      state.drivers.loading += 1;
    });
    builder.addCase(retrieveDriversAction.fulfilled, (state, action) => {
      const data = action.payload?.data || [];
      driverAdapter.setAll(state.drivers, data);
      state.drivers.error = null;
      state.drivers.loading -= 1;
    });
    builder.addCase(retrieveDriversAction.rejected, (state, action) => {
      state.drivers.error = action.payload;
      state.drivers.loading -= 1;
    });

    // loadDriverTableData
    builder.addCase(loadTableDataAction.pending, (state) => {
      state.tableDataLoading += 1;
    });
    builder.addCase(loadTableDataAction.fulfilled, (state) => {
      state.tableDataLoading -= 1;
    });
    builder.addCase(loadTableDataAction.rejected, (state) => {
      state.tableDataLoading -= 1;
    });

    // retrieveDriverJobs
    builder.addCase(retrieveDriverJobsAction.pending, (state) => {
      state.driverJobs.error = null;
      state.driverJobs.loading += 1;
    });
    builder.addCase(retrieveDriverJobsAction.fulfilled, (state, action) => {
      state.driverJobs.data = action.payload?.data;
      state.driverJobs.error = null;
      state.driverJobs.loading -= 1;
    });
    builder.addCase(retrieveDriverJobsAction.rejected, (state, action) => {
      state.driverJobs.error = action.payload;
      state.driverJobs.loading -= 1;
    });

    // dual in matches
    builder.addCase(retrieveDualInMatchesAction.pending, (state) => {
      state.matches.error = null;
      state.matches.loading += 1;
    });
    builder.addCase(retrieveDualInMatchesAction.fulfilled, (state, action) => {
      state.matches.data = action.payload?.data;
      state.matches.error = null;
      state.matches.loading -= 1;
    });
    builder.addCase(retrieveDualInMatchesAction.rejected, (state, action) => {
      state.matches.error = action.payload;
      state.matches.loading -= 1;
    });

    // dual ingate generator leg matches
    builder.addCase(retrieveDualIngateGeneratorLegMatches.pending, (state) => {
      state.generatorMatches.error = null;
      state.generatorMatches.loading += 1;
      state.loading = true;
    });
    builder.addCase(retrieveDualIngateGeneratorLegMatches.fulfilled, (state, action) => {
      state.generatorMatches.data = action.payload?.data;
      state.generatorMatches.error = null;
      state.generatorMatches.loading -= 1;
      state.loading = false;
    });
    builder.addCase(retrieveDualIngateGeneratorLegMatches.rejected, (state, action) => {
      state.generatorMatches.error = action.payload;
      state.generatorMatches.loading -= 1;
      state.loading = false;
    });
  }
});
