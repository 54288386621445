import { TIME_ZONES } from '@drayalliance/types';
import moment from 'moment-timezone';

export function formatAppointment(dateString: string, timezone?: TIME_ZONES) {
  const momentValue = moment(dateString).tz(timezone || TIME_ZONES.AMERICA_LOS_ANGELES);
  if (!dateString || !momentValue.isValid()) {
    return '';
  }

  return momentValue.format('YYYY-MM-DD HH:mm');
}
