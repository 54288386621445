import React, { MouseEvent, useCallback, useState } from 'react';
import Button from '@client/components/Button/component';
import { isEmpty } from 'lodash';
import { SubscriptionType } from '@drayalliance/types';
import { convertStringToList } from '@client/utils/formatString';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { subscribeToContainer, SubscriptionContainerPayload } from '../../../../SubscriptionNotification/stores';
import { convertToSubscribeContainerPayload } from '../../utils/convertToSubscribeContainerPayload';
import SubscribeInput from './Components/SubscribeInput';
import ContainerConfigurationsListComponent from './Components/ContainerConfigurationsList';

import './styles.css';

export interface ContainerConfigurations {
  ingate?: SubscriptionContainerPayload;
  outgate?: SubscriptionContainerPayload;
}

export type HandleContainerConfigUpdate = ({
  containerNumber,
  eventType,
  configurationProperty,
  value
}: {
  containerNumber: string;
  eventType: SubscriptionType;
  configurationProperty: 'data' | 'type';
  value: number | boolean;
}) => void;

export type HandleRemoveAddedContainers = (containerNumber: string) => void;

export type ContainerConfigurationsPayload = Record<string, ContainerConfigurations>;

const configurationsConstants = {
  [SubscriptionType.EventIngateAppointment]: 'ingate',
  [SubscriptionType.EventOutgateAppointment]: 'outgate'
};

function SubscribeToContainers() {
  const baseClassName = 'subscribe-to-container';
  const dispatch = useAppDispatch();
  const featureFlags = useFlags();
  const {
    subscriptionNotificationEventTypes, // https://app.launchdarkly.com/default/test/features/subscription-notification-event-types/variations
    subscriptionEventIngateAppointment, // https://app.launchdarkly.com/default/test/features/subscription-event-ingate-appointment/variations
    subscriptionEventOutgateAppointment // https://app.launchdarkly.com/default/test/features/subscription-event-outgate-appointment/variations
  } = featureFlags;
  const ingateAppointmentSubscriptionDefaultOffset = subscriptionEventIngateAppointment.appointmentTimeOffset;
  const outgateAppointmentSubscriptionDefaultOffset = subscriptionEventOutgateAppointment.appointmentTimeOffset;

  const [showContainersConfigs, setShowContainersConfigs] = useState<boolean>(false);
  const [containersConfigurationsPayload, setContainersConfigurationsPayload] =
    useState<ContainerConfigurationsPayload>({} as ContainerConfigurationsPayload);
  const [containerNumbers, setContainerNumbers] = useState<string>('');

  const handleInputChange = (value: string) => {
    setContainerNumbers(value);
  };

  const handleAddContainer = useCallback((): void => {
    if (containerNumbers.length > 0) {
      setShowContainersConfigs(true);
      const previouslyAdded = Object.keys(containersConfigurationsPayload).join(',');
      const containerNumbersArr = convertStringToList(containerNumbers.concat(',', previouslyAdded));

      const payload = containerNumbersArr.reduce((acc: ContainerConfigurationsPayload, containerNumber) => {
        const ingate = subscriptionNotificationEventTypes[SubscriptionType.EventIngateAppointment]
          ? {
              type: SubscriptionType.EventIngateAppointment,
              data: {
                containerNumber,
                appointmentTimeOffset: ingateAppointmentSubscriptionDefaultOffset
              },
              config: {
                slack: {
                  channelIds: []
                }
              }
            }
          : undefined;

        acc[containerNumber] = {
          outgate: {
            type: SubscriptionType.EventOutgateAppointment,
            data: {
              containerNumber,
              appointmentTimeOffset: outgateAppointmentSubscriptionDefaultOffset
            },
            config: {
              slack: {
                channelIds: []
              }
            }
          },
          ingate
        };
        return acc;
      }, {});
      const currentPayload = containersConfigurationsPayload;

      setContainersConfigurationsPayload({ ...payload, ...currentPayload });
      setContainerNumbers('');
    } else {
      setShowContainersConfigs(false);
    }
  }, [containerNumbers]);

  const handleContainerConfigUpdate: HandleContainerConfigUpdate = ({
    containerNumber,
    eventType,
    configurationProperty,
    value
  }) => {
    const currentContainerConfig = containersConfigurationsPayload[containerNumber];
    const allContainersConfigs = containersConfigurationsPayload;
    const subscriptionType = configurationsConstants[eventType] as keyof ContainerConfigurations;

    switch (configurationProperty) {
      case 'type':
        if (value && !currentContainerConfig[subscriptionType]) {
          currentContainerConfig[subscriptionType] = {
            type: eventType,
            data: {
              containerNumber,
              appointmentTimeOffset:
                subscriptionType === 'ingate'
                  ? ingateAppointmentSubscriptionDefaultOffset
                  : outgateAppointmentSubscriptionDefaultOffset
            },
            config: {
              slack: {
                channelIds: []
              }
            }
          };
          const updatedContainersConfig = { ...allContainersConfigs, [containerNumber]: currentContainerConfig };
          setContainersConfigurationsPayload(updatedContainersConfig);
        } else {
          delete currentContainerConfig[subscriptionType];
          setContainersConfigurationsPayload({ ...allContainersConfigs });
        }
        break;
      case 'data':
        if (eventType === SubscriptionType.EventIngateAppointment && currentContainerConfig?.ingate) {
          currentContainerConfig.ingate.data.appointmentTimeOffset = value as number;
          setContainersConfigurationsPayload({ ...allContainersConfigs });
        }
        break;
      default:
        throw new Error('Invalid Event Type');
    }
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const payload = convertToSubscribeContainerPayload(containersConfigurationsPayload);
    dispatch(subscribeToContainer(payload));
  };

  const handleRemoveAddedContainers: HandleRemoveAddedContainers = (containerNumber) => {
    const updatedPayload = containersConfigurationsPayload;
    delete updatedPayload[containerNumber];
    setContainersConfigurationsPayload({ ...updatedPayload });
  };

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__header-wrapper`}>
        <h2>Subscribe to new containers</h2>
        <p className={`${baseClassName}__header-text`}>Subscribe new containers to the booking appointment scrapers.</p>
      </div>
      <div className={`${baseClassName}__input-wrapper`}>
        <SubscribeInput _handleInputChange={handleInputChange} containerNumbers={containerNumbers} />
        <Button
          disabled={containerNumbers.length < 1}
          id="addContainersToSubscribe"
          label="Add container(s)"
          theme="2"
          onClick={handleAddContainer}
        />
      </div>
      {showContainersConfigs && !isEmpty(containersConfigurationsPayload) && (
        <>
          <ContainerConfigurationsListComponent
            containersConfigurations={containersConfigurationsPayload}
            _handleContainerConfigUpdate={handleContainerConfigUpdate}
            _handleRemoveAddedContainers={handleRemoveAddedContainers}
          />
          <Button id="submitContainers" label="Submit" theme="2" onClick={handleSubmit} />
        </>
      )}
    </div>
  );
}

export default SubscribeToContainers;
