// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-edit__configs-content {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    column-gap: 20px;
    max-width: 615px;
    width: 100%;
    border: 1px solid #b9bfc5;
    margin: 25px 0;
  }
  .subscription-edit__configs {
    display: grid;
    grid-template-columns: 0.3fr 0.5fr;
    align-items: center;
    border-left: 1px solid #b9bfc5;
  }
  .subscription-edit__container-number {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.0025em;
    text-align: left;
    margin: auto;
    width: 100%;
    padding: 20px;
  }
  .subscription-edit__cta-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 220px;
    column-gap: 10px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/SubscriptionEdit/styles.css"],"names":[],"mappings":"AACE;IACE,aAAa;IACb,gCAAgC;IAChC,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,cAAc;EAChB;EAEA;IACE,aAAa;IACb,kCAAkC;IAClC,mBAAmB;IACnB,8BAA8B;EAChC;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,aAAa;EACf;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,gBAAgB;EAClB","sourcesContent":[".subscription-edit {\n  &__configs-content {\n    display: grid;\n    grid-template-columns: 0.2fr 1fr;\n    column-gap: 20px;\n    max-width: 615px;\n    width: 100%;\n    border: 1px solid #b9bfc5;\n    margin: 25px 0;\n  }\n\n  &__configs {\n    display: grid;\n    grid-template-columns: 0.3fr 0.5fr;\n    align-items: center;\n    border-left: 1px solid #b9bfc5;\n  }\n\n  &__container-number {\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 15px;\n    letter-spacing: 0.0025em;\n    text-align: left;\n    margin: auto;\n    width: 100%;\n    padding: 20px;\n  }\n\n  &__cta-group {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    max-width: 220px;\n    column-gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
