import React from 'react';
import { selectTerminalUuidMap, TerminalScraperConfiguration } from '@client/_blessed/store/entities';
import { useSelector } from 'react-redux';
import TimeCircleIcon from '@components/deprecatedTookit/icons/fa/regular/TimesCircleIcon';
import CheckCircleIcon from '@components/deprecatedTookit/icons/fa/regular/CheckCircleIcon';

import './styles.css';

interface Props {
  supportedTerminals: TerminalScraperConfiguration[];
}

function checkIfTerminalIsAlwaysUnspported(terminal: string): boolean {
  const alwaysOffTerminals = ['shippers transport express', 'tbd', 'shippers alameda'];
  return alwaysOffTerminals.includes(terminal?.toLowerCase());
}

function SupportedTerminalBooking(props: Props) {
  const baseClassName = 'supported-terminal-booking';
  const terminalUuidMap = useSelector(selectTerminalUuidMap);
  const tableHeaders = props.supportedTerminals.map((st) => terminalUuidMap[st.terminalUuid]?.nickname);

  function mapTableRow() {
    return props.supportedTerminals.map((terminal) => {
      const onIcon = <CheckCircleIcon className={`${baseClassName}--on`} />;
      const offIcon = <TimeCircleIcon className={`${baseClassName}--off`} />;
      const terminalIsAlwaysOff = checkIfTerminalIsAlwaysUnspported(
        terminalUuidMap?.[terminal.terminalUuid]?.nickname || terminalUuidMap?.[terminal.terminalUuid]?.name
      );
      let outgateIcon = (
        <div>
          {onIcon}
          Out Gate
        </div>
      );
      let ingateIcon = (
        <div>
          {onIcon}
          In Gate
        </div>
      );

      if (!terminal.appointmentBookingEnabled) {
        outgateIcon = (
          <div>
            {offIcon}
            Out Gate
          </div>
        );
      }
      if (!terminal.ingateAppointmentBookingEnabled) {
        ingateIcon = (
          <div>
            {offIcon}
            In Gate
          </div>
        );
      }
      return (
        <td key={terminal.uuid}>
          <div className="status">
            {!terminalIsAlwaysOff && ingateIcon}
            {!terminalIsAlwaysOff && outgateIcon}
            {terminalIsAlwaysOff && <div>{offIcon} Not Supported</div>}
          </div>
        </td>
      );
    });
  }

  return (
    <div className={baseClassName}>
      <h2>Configurations</h2>
      <table className={`${baseClassName}__table`}>
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>{mapTableRow()}</tr>
        </tbody>
      </table>
    </div>
  );
}

export default SupportedTerminalBooking;
