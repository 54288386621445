import { DualIngateSmartMatch } from '@client/_blessed/models';
import { GeneratorLeg } from '@client/_blessed/store/entities';
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { RootState } from '@client/reduxProvider';
import { sliceId } from './sliceId';
import { selectAdminSlice } from '../../../store/selectors';
import { sortBy, sortNullDateColumnsLast } from './utils';
import { ReactiveDualTransPageState } from '.';

export const selectReactiveSlice = createSelector(
  selectAdminSlice,
  (state) => state[sliceId] as ReactiveDualTransPageState
);

export const selectDriverJobs = (state: RootState) => selectReactiveSlice(state).driverJobs.data;

export const selectTableSort = (state: RootState) => selectReactiveSlice(state).tableSort;

export const selectSortedJobs = createSelector([selectDriverJobs, selectTableSort], (driverJobs, tableSort) => {
  let result = [];

  if (driverJobs?.length) {
    let sortedJobs = [...driverJobs].sort(sortBy(tableSort));
    if (
      ['firstStopAppointmentStart', 'lastStopAppointmentStart', 'lastFreeDay', 'perDiemDueDate'].includes(
        tableSort.column
      )
    ) {
      sortedJobs = sortNullDateColumnsLast(sortedJobs, tableSort.column);
    }
    result = sortedJobs;
  }
  return result;
});

export const tableDataSelector = createSelector([selectSortedJobs], (driverJobs) => driverJobs);

export const selectDrivers = (state: RootState) => Object.values(selectReactiveSlice(state).drivers.entities) as any[];

export const selectDriversById = (state: RootState) => selectReactiveSlice(state).drivers.entities;

export const selectDriversAlphabetically = createSelector(selectDrivers, (drivers) => {
  const alphabetically = drivers.sort((a: any, b: any) => (a.attributes.firstName < b.attributes.firstName ? -1 : 1));
  return alphabetically;
});

export const selectDriverDropdownOptions = createSelector(selectDriversAlphabetically, (drivers) =>
  drivers.map((driver: any) => {
    const option = {
      label: `${driver.attributes.firstName.trim()} ${driver.attributes.lastName.trim()}`,
      value: driver.id
    };
    return option;
  })
);

export const selectMatches = (state: RootState) => selectReactiveSlice(state).matches.data;

export const selectMatchSort = (state: RootState) => selectReactiveSlice(state).matchSort;

export const selectSortedMatches = createSelector([selectMatches, selectMatchSort], (matches, sort) => {
  let result = [];

  if (matches?.length) {
    let sortedMatches = [...matches].sort(sortBy(sort));
    if (['perDiemDueDate', 'returnAppointment', 'readyForPickupDate'].includes(sort.column)) {
      sortedMatches = sortNullDateColumnsLast(sortedMatches, sort.column);
    }
    result = sortedMatches;
  }
  return result;
});

export const selectMatchLocations = createSelector([selectMatches], (matches): { value: string; label: string }[] => {
  const stops: string[] = matches
    ?.map((match: DualIngateSmartMatch) => match.firstStopName)
    .filter((stop: string) => stop);
  const uniqueSorted: string[] = _.uniq(stops || []).sort((a: string, b: string) => (a < b ? -1 : 1));
  return uniqueSorted.map((item) => ({ value: item, label: item }));
});

export const selectGeneratorMatches = (state: RootState) => selectReactiveSlice(state).generatorMatches.data;

export const selectMatchesLoading = (state: RootState) => selectReactiveSlice(state).loading;

export const selectSortedGeneratorMatches = createSelector(
  [selectGeneratorMatches, selectMatchSort],
  (matches, sort) => {
    let result = [];

    if (matches?.length) {
      let sortedMatches = [...matches].sort(sortBy(sort));
      if (['perDiemDueDate', 'lastStopStart', 'emptyReadyAt'].includes(sort.column)) {
        sortedMatches = sortNullDateColumnsLast(sortedMatches, sort.column);
      }
      result = sortedMatches;
    }
    return result;
  }
);

export const selectGeneratorMatchLocations = createSelector([selectGeneratorMatches], (matches) => {
  const stopNames: string[] = matches?.map((match: GeneratorLeg) => match.firstStopName).filter((name: string) => name);
  const uniqueSorted: string[] = _.uniq(stopNames || []).sort((a: string, b: string) => (a < b ? -1 : 1));
  return uniqueSorted.map((item) => ({ value: item, label: item }));
});
