import type { SubscriptionNotificationUiData } from '../../features/AppointmentBooking/container';

export const sortNotificationRequests = (notifications: SubscriptionNotificationUiData[], sort: any) => {
  const sorter = (a: SubscriptionNotificationUiData, b: SubscriptionNotificationUiData) => {
    const aVal = a[sort.field as keyof SubscriptionNotificationUiData];
    const bVal = b[sort.field as keyof SubscriptionNotificationUiData];

    if (sort.direction === 'ASC') {
      return (aVal as string) < (bVal as string) ? -1 : 1;
    }
    return (aVal as string) > (bVal as string) ? -1 : 1;
  };
  return [...notifications].sort(sorter);
};

export const searchSubscriptionNotifications = (
  notifications: SubscriptionNotificationUiData[],
  query: string
): SubscriptionNotificationUiData[] => {
  if (!query) {
    return notifications;
  }

  const foundMap: Record<string, SubscriptionNotificationUiData> = {};

  const parsedString = query.split(/[, ]+/);
  parsedString.forEach((str) => {
    notifications.forEach((notification) => {
      const { containerNumber, terminal, subscriber } = notification;
      const containerFound = containerNumber.toLowerCase().search(str.toLowerCase()) !== -1;
      const terminalFound = terminal?.toLowerCase().search(str.toLowerCase()) !== -1;
      const subscriberFound = subscriber?.toLowerCase().search(str.toLowerCase()) !== -1;

      if (containerFound || terminalFound || subscriberFound) {
        foundMap[notification.uuid] = notification;
      }
    });
  });

  return Object.values(foundMap);
};
