// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-table-item__delete-btn {
    display: block;
    width: 25px;
    height: 25px;
    margin: auto;
  }

    .subscription-table-item__delete-btn:hover {
      cursor: pointer;
    }
  .subscription-table-item__check-mark {
    color: green;
  }
  .subscription-table-item__x-mark {
    color: red;
  }
  .subscription-table-item__x-mark,
  .subscription-table-item__check-mark {
    max-width: 1.8rem;
    margin: auto;
  }
  .subscription-table-item__icon {
    width: 18px;
    height: 18px;
    margin-left: 7px;
    color: #b9bfc5;
  }
  .subscription-table-item__edit-button {
    cursor: pointer;
  }
  .subscription-table-item__edit-button > svg {
      color: #000;
    }
  .subscription-table-item__edit-button--disabled {
      cursor: not-allowed;
    }
  .subscription-table-item__edit-button--disabled > svg {
        color: #b9bfc5;
      }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/SubscriptionListItems/styles.css"],"names":[],"mappings":"AACE;IACE,cAAc;IACd,WAAW;IACX,YAAY;IACZ,YAAY;EAKd;;IAHE;MACE,eAAe;IACjB;EAGF;IACE,YAAY;EACd;EAEA;IACE,UAAU;EACZ;EAEA;;IAEE,iBAAiB;IACjB,YAAY;EACd;EAEA;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,cAAc;EAChB;EAEA;IACE,eAAe;EAajB;EAXE;MACE,WAAW;IACb;EAEA;MACE,mBAAmB;IAKrB;EAHE;QACE,cAAc;MAChB","sourcesContent":[".subscription-table-item {\n  &__delete-btn {\n    display: block;\n    width: 25px;\n    height: 25px;\n    margin: auto;\n\n    &:hover {\n      cursor: pointer;\n    }\n  }\n\n  &__check-mark {\n    color: green;\n  }\n\n  &__x-mark {\n    color: red;\n  }\n\n  &__x-mark,\n  &__check-mark {\n    max-width: 1.8rem;\n    margin: auto;\n  }\n\n  &__icon {\n    width: 18px;\n    height: 18px;\n    margin-left: 7px;\n    color: #b9bfc5;\n  }\n\n  &__edit-button {\n    cursor: pointer;\n\n    & > svg {\n      color: #000;\n    }\n\n    &--disabled {\n      cursor: not-allowed;\n\n      & > svg {\n        color: #b9bfc5;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
