import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CheckBox from '@client/components/Checkbox';
import { SubscriptionType } from '@drayalliance/types';
import DropdownSelectInput from '@client/components/DropdownSelectInput';
import TrashIcon from '@components/deprecatedTookit/icons/fa/regular/TrashAltIcon';
import type {
  ContainerConfigurations,
  HandleContainerConfigUpdate,
  HandleRemoveAddedContainers
} from '../../container';
import { DelayProperties, delays } from '../../../../../../SubscriptionNotification/stores/delays';

import './styles.css';

interface Props {
  containerNumber: string;
  containerConfigurations: ContainerConfigurations;
  _handleContainerConfigUpdate: HandleContainerConfigUpdate;
  _handleRemoveAddedContainers: HandleRemoveAddedContainers;
}

function ContainerConfigurationsListItems({
  containerNumber,
  containerConfigurations,
  _handleContainerConfigUpdate,
  _handleRemoveAddedContainers
}: Props) {
  const baseClassName = 'container-configuration-list-item';
  const featureFlags = useFlags();
  const { subscriptionNotificationEventTypes } = featureFlags;

  /**
   * number represents hour of delay
   * if configuration not found, we default to 0
   * because we will not submit subscription for it
   */
  const ingateOffsetTime = containerConfigurations?.ingate?.data.appointmentTimeOffset || 0;
  const outgateOffsetTime = containerConfigurations?.outgate?.data.appointmentTimeOffset || 0;

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__container-number`}>
        <span className={`${baseClassName}__container-text`}>{containerNumber}</span>
        <button
          onClick={() => _handleRemoveAddedContainers(containerNumber)}
          type="button"
          aria-label="Remove added containers button"
        >
          <TrashIcon />
        </button>
      </div>
      <div className={`${baseClassName}__configs`}>
        <div className={`${baseClassName}__event-ingate`}>
          <CheckBox
            label="In Gate"
            checked={
              subscriptionNotificationEventTypes[SubscriptionType.EventIngateAppointment]
                ? !!containerConfigurations.ingate
                : false
            }
            disabled={!subscriptionNotificationEventTypes[SubscriptionType.EventIngateAppointment]}
            onToggle={(value) =>
              _handleContainerConfigUpdate({
                containerNumber,
                eventType: SubscriptionType.EventIngateAppointment,
                configurationProperty: 'type',
                value
              })
            }
          />
          <DropdownSelectInput
            multi={false}
            value={delays[ingateOffsetTime]}
            values={[delays[ingateOffsetTime]]}
            options={Object.values(delays)}
            valueField="value"
            labelField="label"
            disabled={
              !subscriptionNotificationEventTypes[SubscriptionType.EventIngateAppointment] ||
              !containerConfigurations?.ingate
            }
            onChange={(value: DelayProperties[]) =>
              _handleContainerConfigUpdate({
                containerNumber,
                eventType: SubscriptionType.EventIngateAppointment,
                configurationProperty: 'data',
                value: value[0]?.value || delays[0].value
              })
            }
          />
          <CheckBox label="Notify Me" disabled checked />
        </div>
        <div className={`${baseClassName}__event-outgate`}>
          <CheckBox
            label="Out Gate"
            checked={!!containerConfigurations.outgate}
            onToggle={(value) =>
              _handleContainerConfigUpdate({
                containerNumber,
                eventType: SubscriptionType.EventOutgateAppointment,
                configurationProperty: 'type',
                value
              })
            }
          />
          <DropdownSelectInput
            multi={false}
            value={delays[outgateOffsetTime]}
            values={[delays[outgateOffsetTime]]}
            options={Object.values(delays)}
            valueField="value"
            labelField="label"
            disabled
            onChange={(value: DelayProperties[]) =>
              _handleContainerConfigUpdate({
                containerNumber,
                eventType: SubscriptionType.EventOutgateAppointment,
                configurationProperty: 'data',
                value: value[0]?.value || delays[0].value
              })
            }
          />
          <CheckBox label="Notify Me" disabled checked />
        </div>
      </div>
    </div>
  );
}

export default React.memo(ContainerConfigurationsListItems);
