// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-table {
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  row-gap: 15px;
}

  .subscription-table__textarea {
    width: 75%;
  }

  .subscription-table__header-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .subscription-table table {
    border: 1px solid var(--color-grayscale-5);
    width: 100%;
    text-align: left;
  }

  .subscription-table table th {
      font-weight: 500;
      background: var(--color-grayscale-2);
    }

  .subscription-table table th,
    .subscription-table table td {
      padding: 24px 27px;
      border-left: solid thin #e5e7ea;
      text-align: center;
    }

  .subscription-table table tr {
      border: 1px solid #ccc;
    }

  .subscription-table__sort-btn {
    cursor: pointer;
    padding: 0;
  }

  .subscription-table__sort-btn:hover {
      color: var(--color-red-3);
    }

  .subscription-table__tool-tip-wrapper {
    display: inline-block;
    margin-left: 5px;
  }

  .subscription-table__subscriptions-tool-tip {
    width: 14px;
    height: 14px;
    font-size: 12px;
    border-radius: 10px;
    text-transform: lowercase;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/SubscriptionTable/styles.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,aAAa;AAsDf;;EApDE;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,0CAA0C;IAC1C,WAAW;IACX,gBAAgB;EAiBlB;;EAfE;MACE,gBAAgB;MAChB,oCAAoC;IACtC;;EAEA;;MAEE,kBAAkB;MAClB,+BAA+B;MAC/B,kBAAkB;IACpB;;EAEA;MACE,sBAAsB;IACxB;;EAGF;IACE,eAAe;IACf,UAAU;EAKZ;;EAHE;MACE,yBAAyB;IAC3B;;EAGF;IACE,qBAAqB;IACrB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,yBAAyB;EAC3B","sourcesContent":[".subscription-table {\n  flex-direction: column;\n  display: flex;\n  width: 100%;\n  height: 100%;\n  max-height: 100vh;\n  row-gap: 15px;\n\n  &__textarea {\n    width: 75%;\n  }\n\n  &__header-wrapper {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n  }\n\n  table {\n    border: 1px solid var(--color-grayscale-5);\n    width: 100%;\n    text-align: left;\n\n    th {\n      font-weight: 500;\n      background: var(--color-grayscale-2);\n    }\n\n    th,\n    td {\n      padding: 24px 27px;\n      border-left: solid thin #e5e7ea;\n      text-align: center;\n    }\n\n    tr {\n      border: 1px solid #ccc;\n    }\n  }\n\n  &__sort-btn {\n    cursor: pointer;\n    padding: 0;\n\n    &:hover {\n      color: var(--color-red-3);\n    }\n  }\n\n  &__tool-tip-wrapper {\n    display: inline-block;\n    margin-left: 5px;\n  }\n\n  &__subscriptions-tool-tip {\n    width: 14px;\n    height: 14px;\n    font-size: 12px;\n    border-radius: 10px;\n    text-transform: lowercase;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
