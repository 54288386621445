import { createSelector } from '@reduxjs/toolkit';
import { OrderJsonApiItem, SubscriptionType } from '@drayalliance/types';
import { drayDateTime } from '@drayalliance/utils';
import Department from '@client/_blessed/store/entities/departments/model';
import { User } from '@client/_blessed/store/entities/users/model';
import { selectAdminSlice } from '../../../../store/selectors';
import { sliceId, subscriptionNotificationAdapter, SubscriptionNotifications } from '.';
import type { SubscriptionNotificationUiData } from '../../features/AppointmentBooking/container';
import { searchSubscriptionNotifications, sortNotificationRequests } from './utils';

interface SelectSubscriptionNotificationsUiData {
  usersMap: Record<string, User>;
  terminalUuidMap: Record<string, Department>;
  ordersMap: Record<string, OrderJsonApiItem> | null | undefined;
}

interface SelectSortedSubscriptionNotificationData extends SelectSubscriptionNotificationsUiData {
  query: string;
}

export const subscriptionType = {
  [SubscriptionType.EventOutgateAppointment]: 'Out Gate',
  [SubscriptionType.EventIngateAppointment]: 'In Gate'
};

export const selectSubscriptionNotificationsSlice = createSelector(selectAdminSlice, (state) => state[sliceId]);

export const { selectAll: selectAllSubscriptionNotifications, selectById: selectSubscriptionById } =
  subscriptionNotificationAdapter.getSelectors(selectSubscriptionNotificationsSlice);

export const selectSorting = createSelector(selectSubscriptionNotificationsSlice, (state) => state.sorting);

export const selectSubscriptionNotificationsUiData = ({
  usersMap,
  ordersMap,
  terminalUuidMap
}: SelectSubscriptionNotificationsUiData) =>
  createSelector(
    selectAllSubscriptionNotifications,
    (subscriptionNotifications: SubscriptionNotifications[]): SubscriptionNotificationUiData[] => {
      const result = subscriptionNotifications.map((subscription) => {
        const { requestorUuid, requestorUUID, containerNumber, uuid, entityId, data, type } = subscription;
        const requestor = requestorUUID || requestorUuid;
        const { firstName, lastName } = usersMap?.[requestor] || {};
        const order = ordersMap?.[entityId];

        const { attributes: orderAttr, relationships } = order || {};

        const pickupAccountUuid = relationships?.pickupAccount?.data?.id;
        const isTerminal = terminalUuidMap?.[pickupAccountUuid as string];
        const sslName = isTerminal
          ? terminalUuidMap?.[pickupAccountUuid as string]?.nickname ||
            terminalUuidMap?.[pickupAccountUuid as string]?.name
          : 'N/A';
        const lastChecked = orderAttr?.lastChecked;
        const lfd = orderAttr?.lastFreeDay;
        const perDiemDueDate = orderAttr?.perDiemDueDate;

        return {
          uuid,
          containerNumber,
          terminal: sslName,
          lfd: lfd ? drayDateTime(lfd).format('YYYY-MM-DD') : 'N/A',
          perDiemDueDate: perDiemDueDate ? drayDateTime(perDiemDueDate).format('YYYY-MM-DD') : 'N/A',
          bookingDelay: data.appointmentTimeOffset || 0,
          subscriptions: subscriptionType[type as SubscriptionType],
          subscriber: `${firstName} ${lastName}`,
          lastChecked: lastChecked ? drayDateTime(lastChecked).format('YYYY-MM-DD') : 'N/A'
        };
      });

      return result;
    }
  );

export const selectSearchedSubscriptionNotificationData = ({
  usersMap,
  ordersMap,
  terminalUuidMap,
  query
}: SelectSortedSubscriptionNotificationData) =>
  createSelector(
    selectSubscriptionNotificationsUiData({ usersMap, ordersMap, terminalUuidMap }),
    (subscriptionNotification: SubscriptionNotificationUiData[]): SubscriptionNotificationUiData[] =>
      searchSubscriptionNotifications(subscriptionNotification, query)
  );

export const selectSortedSubscriptionNotificationData = ({
  usersMap,
  ordersMap,
  terminalUuidMap,
  query
}: SelectSortedSubscriptionNotificationData) =>
  createSelector(
    selectSearchedSubscriptionNotificationData({ usersMap, ordersMap, terminalUuidMap, query }),
    selectSorting,
    (subscriptionNotification: SubscriptionNotificationUiData[], sort: any): SubscriptionNotificationUiData[] =>
      sortNotificationRequests(subscriptionNotification, sort)
  );

export const selectShowSubscribeComponent = createSelector(
  selectSubscriptionNotificationsSlice,
  (state) => state.showSubscribeComponent
);

export const selectShowEditSubscriptionComponent = createSelector(
  selectSubscriptionNotificationsSlice,
  (state) => state.showSubscriptionEditComponent
);

export const selectUuidForEdit = createSelector(selectSubscriptionNotificationsSlice, (state) => state.uuidForEdit);

export const selectLoading = createSelector(selectSubscriptionNotificationsSlice, (state) => state.loading);
