// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DriverJobsTable {
  width: 100%;
  margin-top: 20px;
}

  .DriverJobsTable .CopyButton {
    display: inline;
    position: relative;
    left: 4px;
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }

  .DriverJobsTable__actionable-row:hover {
    cursor: pointer;
    background-color: var(--color-green-1);
  }

  .DriverJobsTable td:hover .CopyButton, .DriverJobsTable *:hover > .CopyButton {
    opacity: 1;
  }

  .DriverJobsTable th {
    text-align: left;
  }

  .DriverJobsTable td {
    padding: 10px 0;
  }`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/ReactiveDualTrans/features/DriverJobsTable/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AA0BlB;;EAxBE;IACE,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,mCAAmC;EACrC;;EAEA;IACE,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".DriverJobsTable {\n  width: 100%;\n  margin-top: 20px;\n\n  .CopyButton {\n    display: inline;\n    position: relative;\n    left: 4px;\n    opacity: 0;\n    transition: opacity .2s ease-in-out;\n  }\n\n  &__actionable-row:hover {\n    cursor: pointer;\n    background-color: var(--color-green-1);\n  }\n\n  td:hover .CopyButton, *:hover > .CopyButton {\n    opacity: 1;\n  }\n\n  th {\n    text-align: left;\n  }\n\n  td {\n    padding: 10px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
