import Drawer from '@client/components/Drawer';
import { DualIngateSmartMatch } from '@client/_blessed/models';
import { ContainerOnStatusEntity, GeneratorLeg } from '@client/_blessed/store/entities';
import DualInMatches from '../DualInMatches';

interface Props {
  selectedLeg: any;
  departmentsById: any;
  driver: any;
  matches: DualIngateSmartMatch[];
  generatorMatches: GeneratorLeg[];
  matchSort: any;
  matchesLoading?: boolean;
  locationOptions: { value: string }[];
  useNewBundles: boolean;
  onHireStatusesByContainer: Record<string, ContainerOnStatusEntity>;
  onCloseDrawer: (e: any) => void;
  onMatchSort: (value: any) => void;
}

function DualInMatchesSidebar(props: Props) {
  const drawerClassName = props.selectedLeg
    ? 'right-sidebar-navigation__content right-sidebar-navigation__content-open'
    : 'right-sidebar-navigation__content';

  return (
    <Drawer
      contentClassName={drawerClassName}
      anchor="right"
      open={!!props.selectedLeg}
      onClose={props.onCloseDrawer}
      disabled={!props.selectedLeg}
    >
      <DualInMatches {...props} />
    </Drawer>
  );
}

export default DualInMatchesSidebar;
