// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscribe-to-container {
  max-width: 1150px;
}

  .subscribe-to-container__input-wrapper :last-child {
      margin-top: 15px;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/Scraper/features/AppointmentBooking/components/SubscribeToContainers/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AAOnB;;EAJI;MACE,gBAAgB;IAClB","sourcesContent":[".subscribe-to-container {\n  max-width: 1150px;\n\n  &__input-wrapper {\n    :last-child {\n      margin-top: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
